import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLazyViewSemiAutomationSchedulesByPowerConsumerQuery } from "../../../../../../redux/api/schedule/scheduleAPI";
import { useLazyViewDemandControlRequestsBySmartPlugQuery } from "../../../../../../redux/api/usage-guide/usageGuideAPI";
import ViewDemandControlRequestsBySmartPlugResponseDTO from "../../../../../../shared/oversight-core/dtos/response-dtos/view-demand-control-requests-by-smart-plug-response-dto";
import ViewSemiAutomationSchedulesByPowerConsumerResponseDTO from "../../../../../../shared/oversight-core/dtos/response-dtos/view-semi-automation-schedules-by-power-consumer-response-dto";
import { ESmartControllerType } from "../../../../../../shared/oversight-core/enums/smart-controller-type";
import { IDemandControlRequestView } from "../../../../../../shared/oversight-core/interfaces/demand-control-request-view";
import { IExtendedShallowSemiAutomatedScheduleView } from "../../../../../../shared/oversight-core/interfaces/shallow-semi-automated-schedule-view";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../../../shared/oversight-core/ui-elements/modal-container/modal-container";

interface IProps extends ModelContainerProps {
  show: boolean;
  controllerName: string;
  smartDeviceType: ESmartControllerType;
  spaceClusterId: string;
  linkedPowerConsumerId: string;
  smartPlugId: string;
}

const RemoveSmartControllerModal = (props: IProps) => {
  const { show, spaceClusterId, linkedPowerConsumerId, smartPlugId, ...rest } =
    props;

  const [isRunningSchedule, setIsRunningSchedule] = useState(false);
  const [isRunningDrpEvents, setIsRunningDrpEvents] = useState(false);
  const [drpEvents, setDrpEvents] = useState<IDemandControlRequestView[]>([]);
  const [semiAutomationSchedule, setSemiAutomationSchedule] = useState<
    IExtendedShallowSemiAutomatedScheduleView[]
  >([]);

  const [triggerSemiAutomationSchedulesByPowerConsumer] =
    useLazyViewSemiAutomationSchedulesByPowerConsumerQuery();
  const [triggerDemandControlRequestsBySmartPlug] =
    useLazyViewDemandControlRequestsBySmartPlugQuery();

  useEffect(() => {
    if (show) {
      triggerSemiAutomationSchedulesByPowerConsumer({
        spaceClusterId: spaceClusterId,
        linkedPowerConsumerId: linkedPowerConsumerId,
      })
        .unwrap()
        .then((res: ViewSemiAutomationSchedulesByPowerConsumerResponseDTO) => {
          setSemiAutomationSchedule(
            res.shallowSemiAutomatedScheduleViews.map((schedule) => {
              return { ...schedule, isSelected: false };
            })
          );
          setIsRunningSchedule(
            res.shallowSemiAutomatedScheduleViews.some((sh) => sh.isRunning)
          );
        })
        .catch(() => {
          setSemiAutomationSchedule([]);
        });
    }
  }, [
    show,
    spaceClusterId,
    linkedPowerConsumerId,
    triggerSemiAutomationSchedulesByPowerConsumer,
  ]);

  useEffect(() => {
    if (show) {
      triggerDemandControlRequestsBySmartPlug({ smartPlugId: smartPlugId })
        .unwrap()
        .then((res: ViewDemandControlRequestsBySmartPlugResponseDTO) => {
          setIsRunningDrpEvents(
            res.demandControlRequests.some((dc) => dc.isRunning)
          );
          setDrpEvents(res.demandControlRequests);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [show, smartPlugId, triggerDemandControlRequestsBySmartPlug]);

  return (
    <ModalContainer
      show={show}
      {...rest}
      title="Remove Controller"
      confirmButtonVariant="red"
      confirmButtonText="Remove"
      size="modal-md"
    >
      <>
        <Row>
          <Col className="text-light font-size-14 m-0">
            {props.controllerName} will be removed permanently, Are you sure you
            want to delete this{" "}
            {props.smartDeviceType === ESmartControllerType.SMART_PLUG
              ? `Smart controller`
              : `AC controller`}
            ? This action cannot be undone and all associated data will be
            permanently deleted.
          </Col>
        </Row>
        <Row className="mt-2">
          <Col className="text-light font-size-14 m-0">
            If this controller has any upcoming schedules or DRP events, the
            controller will be removed from them.
          </Col>
        </Row>
        <Row className="mt-2">
          <Col className="text-light font-size-14 font-weight-500 m-0">
            {isRunningDrpEvents && isRunningSchedule ? (
              <>
                There are active semi-auto schedules and DRP events on this
                controller. The semi-auto schedules that are currently in effect
                are{" "}
                {semiAutomationSchedule
                  .filter((sh) => sh.isRunning)
                  .map((sch) => `"${sch.title}"`)
                  .join(", ")}{" "}
                and The DRP events that are currently in effect are{" "}
                {drpEvents
                  .filter((drpEv) => drpEv.isRunning)
                  .map((drp) => `"${drp.title}"`)
                  .join(", ")}
                .
              </>
            ) : isRunningSchedule ? (
              <>
                There are active semi-auto schedules on this controller. The
                semi-auto schedules that are currently in effect are{" "}
                {semiAutomationSchedule
                  .filter((sh) => sh.isRunning)
                  .map((sch) => `"${sch.title}"`)
                  .join(", ")}
                .
              </>
            ) : (
              isRunningDrpEvents && (
                <>
                  There are active DRP events on this controller. The DRP events
                  that are currently in effect are{" "}
                  {drpEvents
                    .filter((drpEv) => drpEv.isRunning)
                    .map((drp) => `"${drp.title}"`)
                    .join(", ")}
                  .
                </>
              )
            )}
          </Col>
        </Row>
        <Row className="mt-2">
          <Col className="text-light font-size-14 m-0">
            Even if you remove a smart controller, its historical{" "}
            {props.smartDeviceType === ESmartControllerType.SMART_PLUG &&
              `power usage
            and`}{" "}
            executed semi-automated jobs will be preserved.
          </Col>
        </Row>
      </>
    </ModalContainer>
  );
};

export default RemoveSmartControllerModal;
