import { format } from "date-fns";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  useAddEnergyGenerationMutation,
  useUpdateEnergyGenerationMutation,
} from "../../../../../../redux/api/solar/solarAPI";
import AppInput from "../../../../../../shared/oversight-core/ui-elements/input/app-input";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../../../shared/oversight-core/ui-elements/modal-container/modal-container";
import { decimalRegex } from "../../../../../../shared/oversight-core/utils/regex";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../../../shared/oversight-core/utils/toast";

interface IFormInput {
  generatedPower: number | undefined;
}

const defaultFormValues: IFormInput = {
  generatedPower: undefined,
};
interface IProps extends ModelContainerProps {
  closeAddUpdateSolarGenerationDataModal: () => void;
  month: Date;
  isEditMode?: boolean;
  powerGeneratorId: string;
  year: string;
  updateMonthlyEnergyGeneration: () => void;
  actualData: number;
  show: boolean;
}
const AddUpdateSolarGenerationDataModal = (props: IProps) => {
  const { ...rest } = props;
  const [addEnergyGeneration, { isLoading: isLoadingAddEnergyGeneration }] =
    useAddEnergyGenerationMutation();

  const [
    updateEnergyGeneration,
    { isLoading: isLoadingUpdateEnergyGeneration },
  ] = useUpdateEnergyGenerationMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<IFormInput>({
    defaultValues: { ...defaultFormValues },
  });

  useEffect(() => {
    if (props.actualData && props.show) {
      setValue("generatedPower", props.actualData);
    } else {
      setValue("generatedPower", undefined);
    }
  }, [props.actualData, props.show]);

  const requestSuccessHandler = (message: string) => {
    showSuccessMessage(message);
    reset({ ...defaultFormValues });
    props.closeAddUpdateSolarGenerationDataModal();
    props.updateMonthlyEnergyGeneration();
  };

  const onSubmit = (data: IFormInput) => {
    const month = parseInt(format(props.month, "M"));
    const year = parseInt(props.year);
    const powerGeneratorId: string = props.powerGeneratorId;
    if (!props.actualData) {
      addEnergyGeneration({
        powerGeneratorId,
        year,
        month,
        generatedEnergyInKiloWattHours: +(data.generatedPower || 0),
      })
        .unwrap()
        .then(() => {
          requestSuccessHandler("Solar Generation Details Added Successfully");
        })
        .catch(() => {
          showErrorMessage("Adding Solar Generation Details Unsuccessful");
        });
    } else {
      updateEnergyGeneration({
        powerGeneratorId,
        year,
        month,
        generatedEnergyInKiloWattHours: +(data.generatedPower || 0),
      })
        .unwrap()
        .then(() => {
          requestSuccessHandler(
            "Solar Generation Details Updated Successfully"
          );
        })
        .catch(() => {
          showErrorMessage("Editing Solar Generation Details Unsuccessful");
        });
    }
  };

  return (
    <ModalContainer
      {...rest}
      title={
        props.actualData
          ? "Edit Solar Energy Generation"
          : "Add Solar Energy Generation"
      }
      size="modal-lg"
      onConfirm={handleSubmit(onSubmit)}
      isLoading={
        isLoadingAddEnergyGeneration || isLoadingUpdateEnergyGeneration
      }
      onClose={() => {
        reset({ ...defaultFormValues });
        if (props.onClose) {
          props.onClose();
        }
      }}
      onCancel={() => {
        reset({ ...defaultFormValues });
        if (props.onCancel) {
          props.onCancel();
        }
      }}>
      <>
        <div className="text-muted font-size-20 font-weight-500">
          {format(props.month, "MMMM")} {props.year}
        </div>
        <AppInput
          className="mt-4"
          type="number"
          label="Generated Solar Energy (kWh)"
          placeholder="Generated Solar Energy (kWh)"
          name="generatedPower"
          register={register("generatedPower", {
            required: "Amount of solar generated is required",
            min: {
              value: 1,
              message: "Please enter a value greater than 0",
            },
            pattern: {
              value: decimalRegex,
              message: "Can't be more than two decimal places",
            },
          })}
          errors={errors}
        />
      </>
    </ModalContainer>
  );
};

export default AddUpdateSolarGenerationDataModal;
