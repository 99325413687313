import { Col, Row } from "react-bootstrap";
import ArrowButton from "../../../../../shared/oversight-core/ui-elements/arrow-button/arrow-button";
import AppButton from "../../../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import StepsNumber from "../../../../../shared/oversight-core/ui-elements/steps-number/steps-number";

interface IProps {
  onBack: () => void;
  onConfirm: () => void;
  selectedStep: number;
  isEdit: boolean;
  isDisabled: boolean;
}

const JoinDrpHeader = (props: IProps) => {
  const { onBack, onConfirm, isDisabled, selectedStep, isEdit } = props;

  return (
    <div className="container-white">
      <Row className="align-items-start">
        <Col>
          <Row>
            <Col className="col-auto">
              <ArrowButton onBack={() => onBack()} />
            </Col>
            <Col className="mt-1">
              <Row>
                <Col className="text-dark font-weight-500 font-size-16">
                  {isEdit ? `Edit` : `Join`} Demand Response Program
                </Col>
              </Row>
              <Row>
                <Col className="text-light font-weight-400 font-size-14">
                  {selectedStep === 1 ? (
                    <>
                      <div>Select devices that you connect to DRP.</div>
                      <div className="mt-2">
                        High Priority : Turn off only if necessary.
                      </div>
                      <div>Low Priority : It&apos;s fine to turn off.</div>
                      <div>
                        Don&apos;t Add to DRP : Device won&apos;t be controlled
                        by service provider
                      </div>
                    </>
                  ) : (
                    <div>
                      Set priority levels of those devices that you selected for
                      DRP.
                      <br /> Drag and place devices from highest priority to
                      lowest priority
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col className="col-12 col-xl-auto mt-4 mt-xl-0">
          <Row className="mx-3 mx-xl-0">
            <Col>
              <StepsNumber
                steps={[
                  { id: 1, stepName: "Select Devices" },
                  { id: 2, stepName: "Set Priority" },
                ]}
                selectedStep={selectedStep}
              />
            </Col>
          </Row>
          <Row className="mx-3 mx-xl-0 mt-4 justify-content-xl-end">
            <Col className="col-auto">
              <AppButton
                text={selectedStep === 1 ? `Next` : `Confirm`}
                size="medium"
                onClick={() => onConfirm()}
                className="px-5"
                disabled={isDisabled}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default JoinDrpHeader;
