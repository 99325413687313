import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useLazyGetAllPowerGeneratorsQuery } from "../../../redux/api/solar/solarAPI";
import { useLazyGetSpaceClustersWithDepthQuery } from "../../../redux/api/space/spaceAPI";
import { IGetSpaceClusters } from "../../../shared/oversight-core/dtos/response-dtos/get-space-clusters-dto";
import ViewEnergyGenerationResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-energy-generation-response-dto";
import { AppRoute } from "../../../shared/oversight-core/interfaces/app-routes";
import ISimplifiedPowerGeneratorView from "../../../shared/oversight-core/interfaces/simplified-power-generator-view";
import AppDatePicker from "../../../shared/oversight-core/ui-elements/app-date-picker/app-date-picker";
import AreaChart from "../../../shared/oversight-core/ui-elements/area-chart/area-chart";
import SpinnerModal from "../../../shared/oversight-core/ui-elements/spinner/spinner";
import AddUpdateSolarDeviceModal from "./components/modals/add-update-solar-device-modal/add-update-solar-device-modal";
import styles from "./solar.module.scss";
import StatsViewHeader from "./stats-view-header/stats-view-header";

const Solar = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [generatedPower, setGeneratedPower] = useState([0]);
  const [months, setMonths] = useState([""]);
  const [spaceClusterList, setSpaceClusterList] = useState<IGetSpaceClusters[]>(
    []
  );
  const [solarList, setSolarList] = useState<ISimplifiedPowerGeneratorView[]>(
    []
  );
  const [solarDataFetching, setIsSolrDataFetching] = useState(false);
  const [showAddUpdateSolarDeviceModal, setShowAddUpdateSolarDeviceModal] =
    useState(false);
  const [solarSpaceClusterList, setSolarSpaceClusterList] = useState<string[]>(
    []
  );

  const [
    triggerGetAllPowerGenerators,
    { isFetching: isFetchingPowerGenerators },
  ] = useLazyGetAllPowerGeneratorsQuery();

  const getAllPowerGenerators = () => {
    triggerGetAllPowerGenerators()
      .unwrap()
      .then((response) => {
        setSolarList(response.spaceClusterWithPowerGeneratorViews);
        const tempSolarSpaceClusterList =
          response.spaceClusterWithPowerGeneratorViews.map((powerGenerator) => {
            return powerGenerator.id;
          });

        setSolarSpaceClusterList(tempSolarSpaceClusterList);
      });
  };

  useEffect(() => {
    getAllPowerGenerators();
  }, []);

  const closeAddUpdateSolarDeviceModal = () => {
    setShowAddUpdateSolarDeviceModal(false);
  };

  const updateSolarData = (
    energyGeneration: ViewEnergyGenerationResponseDTO,
    isFetching: boolean
  ) => {
    const { generatedEnergy, months } = energyGeneration;

    setGeneratedPower(generatedEnergy);
    setMonths(months);
    setIsSolrDataFetching(isFetching);
  };

  const [triggerGetSpaceClusters, { isFetching: isFetchingSpaceClusters }] =
    useLazyGetSpaceClustersWithDepthQuery();

  useEffect(() => {
    triggerGetSpaceClusters(0)
      .unwrap()
      .then((response) => {
        setSpaceClusterList(response.spaceClusters);
      });
  }, [triggerGetSpaceClusters]);

  const getContents = () => {
    if (spaceClusterList.length <= 0) {
      return (
        <div className="position-relative mt-4">
          {!isFetchingSpaceClusters && (
            <div className="container-dash">
              <Row>
                <Col className="text-center text-light font-size-12">
                  You have not created any billing spaces.{" "}
                  <Link to={AppRoute.SPACE_CLUSTERS}>Create Billing Space</Link>{" "}
                </Col>
              </Row>
            </div>
          )}
          <SpinnerModal show={isFetchingSpaceClusters} />
        </div>
      );
    } else if (solarList.length <= 0) {
      return (
        <div className="position-relative mt-4">
          {!isFetchingPowerGenerators && (
            <div className="container-dash">
              <Row>
                <Col className="text-center text-light font-size-12">
                  You have not created any solar devices.{" "}
                  <span
                    onClick={() => {
                      setShowAddUpdateSolarDeviceModal(true);
                    }}
                    style={{ color: "#0000EE", textDecoration: "underline" }}
                    className="cursor-pointer"
                  >
                    Create Solar Device
                  </span>
                </Col>
              </Row>
            </div>
          )}
          <SpinnerModal show={isFetchingPowerGenerators} />
        </div>
      );
    } else {
      return (
        <>
          <StatsViewHeader
            updateSolarData={updateSolarData}
            selectedDate={selectedDate}
            getAllPowerGenerators={getAllPowerGenerators}
          />
          <div className={`${styles.solrChartOverflow} container-white mt-3`}>
            <Row className="justify-content-end mb-3">
              <Col className="col-md-4 col-lg-3 col-xl-2 mb-2">
                <AppDatePicker
                  selectedDate={selectedDate}
                  onChange={(date) => {
                    setSelectedDate(date);
                  }}
                  dateFormat="yyyy"
                  monthYearPicker={false}
                  yearPicker={true}
                />
              </Col>
            </Row>
            <div className="position-relative">
              <AreaChart
                borderColor1={"#29CC39"}
                backgroundColor1={"#29CC3917"}
                label1={"Solar Generation"}
                labels={months.map(
                  (m) => m.charAt(0).toUpperCase() + m.slice(1).toLowerCase()
                )}
                data1={generatedPower || Array.from({ length: 12 }, () => 0)}
                yAxesUnit={"kWh"}
                isChartDataAvailable={generatedPower?.some((data) => data > 0)}
              />
              <SpinnerModal
                show={
                  isFetchingSpaceClusters ||
                  isFetchingPowerGenerators ||
                  solarDataFetching
                }
              />
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <>
      {getContents()}
      <AddUpdateSolarDeviceModal
        closeAddUpdateSolarDeviceModal={closeAddUpdateSolarDeviceModal}
        onClose={() => setShowAddUpdateSolarDeviceModal(false)}
        onCancel={() => setShowAddUpdateSolarDeviceModal(false)}
        show={showAddUpdateSolarDeviceModal}
        updateSolarPV={() => {
          getAllPowerGenerators();
          setShowAddUpdateSolarDeviceModal(false);
        }}
        isEdit={false}
        solarSpaceClusterList={solarSpaceClusterList}
      />
    </>
  );
};

export default Solar;
