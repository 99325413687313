import { Col, Row } from "react-bootstrap";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../../../shared/oversight-core/ui-elements/modal-container/modal-container";

interface IProps extends ModelContainerProps {
  show: boolean;
  deviceName: string;
  controllerName: string;
}

const SpecificControllerConvertToGeneralControllerConfirmModal = (
  props: IProps
) => {
  const { show, deviceName, controllerName, ...rest } = props;

  return (
    <ModalContainer
      {...rest}
      show={show}
      size="modal-md"
      confirmButtonText="Confirm"
      cancelButtonText="Cancel"
    >
      <>
        <Row>
          <Col className="font-weight-400 font-size-14 text-light">
            <p>
              Are you sure you want to convert the{" "}
              <span className="font-weight-500">{controllerName}</span> to a
              general type?{" "}
              {deviceName && (
                <>
                  This will unlink the{" "}
                  <span className="font-weight-500">{controllerName}</span> from{" "}
                  <span className="font-weight-500">{deviceName}</span>.
                </>
              )}
            </p>
            <p className="font-weight-500 font-size-14 text-light mt-3 mb-0">
              Cancel Semi-Automated Schedules?
            </p>
            <span>
              All the schedules related to this controller will be canceled due
              to the controller being converted to general.
            </span>
            <p className="font-weight-500 font-size-14 text-light mt-3 mb-0">
              Cancel Demand Response Program Events?
            </p>
            <p className="font-weight-400 font-size-14 text-light">
              This controller will be unavailable for current DRP events due to
              the device being a general type.
            </p>
          </Col>
        </Row>
      </>
    </ModalContainer>
  );
};

export default SpecificControllerConvertToGeneralControllerConfirmModal;
