import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useLazyDemandResponseProgrammeJoinStatusCheckQuery,
  useLazyViewWeeklyDemandControlActionsQuery,
} from "../../../redux/api/usage-guide/usageGuideAPI";
import {
  selectUsageGuideFilter,
  setUsageGuideFilter,
} from "../../../redux/features/usage-guide-filter/usage-guide-filter-slice";
import DemandResponseProgrammeJoinStatusCheckResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/demand-response-programme-join-status-check-response-dto";
import ViewWeeklyDemandControlActionsResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/view-weekly-demand-control-actions-response-dto";
import { EDemandControlRequestType } from "../../../shared/oversight-core/enums/demand-control-request-type";
import { ELimitationType } from "../../../shared/oversight-core/enums/limitation-type";
import { EPowerMeasureType } from "../../../shared/oversight-core/enums/power-measure-type";
import { AppRoute } from "../../../shared/oversight-core/interfaces/app-routes";
import { IDemandControlRequestView } from "../../../shared/oversight-core/interfaces/demand-control-request-view";
import {
  DataColumn,
  Header,
  TimeGridEvent,
} from "../../../shared/oversight-core/shared-components/calendar/models";
import useTimeGrid, {
  sundayStartToMondayStart,
} from "../../../shared/oversight-core/shared-components/calendar/useTimeGrid";
import AppDatePicker from "../../../shared/oversight-core/ui-elements/app-date-picker/app-date-picker";
import AppButton from "../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import MaterialIcon from "../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import NavigateWeeks from "../../../shared/oversight-core/ui-elements/navigate-weeks/navigate-weeks";
import SpinnerModal from "../../../shared/oversight-core/ui-elements/spinner/spinner";
import {
  getMonthRangeToDisplay,
  getWeekRange,
} from "../../../shared/oversight-core/utils/date-utils";
import { powerConvertor } from "../../../shared/oversight-core/utils/power-convertor";
import { scrollToView } from "../../../shared/oversight-core/utils/scroll-to-view";
import CreateModal from "./components/modals/create-modal/create-modal";
import UsageGuideInfoModal from "./components/modals/usage-guide-info-modal/usage-guide-info-modal";
import styles from "./usage-guide.module.scss";

type ColumnDataType = Date | string; // x-axis can be Date or Device ID

const UsageGuide = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const usageGuideFilterStore = useSelector(selectUsageGuideFilter);

  const [events, setEvents] = useState<
    DataColumn<Date, IDemandControlRequestView>[]
  >([]);
  const [weekRange, setWeekRange] = useState<Date[]>(
    getWeekRange(new Date(), usageGuideFilterStore.selectedDate)
  );
  // const [usageGuideResponse, setUsageGuideResponse] =
  //   useState<IShallowPowerUsageGuideView[]>();
  const [demandResponseProgramResponse, setDemandResponseProgramResponse] =
    useState<IDemandControlRequestView[]>();
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [isGoToCurrentTime, setIsGoToCurrentTime] = useState(false);
  const [showUsageGuideInfoModal, setShowUsageGuideInfoModal] = useState(false);
  const [demandControlRequestId, setDemandControlRequestId] = useState("");

  // const [triggerWeeklyGuideQuery, { isFetching: loadingViewWeeklyUsageGuide }] =
  //   useLazyViewWeeklyUsageGuideQuery();
  const [triggerDemandResponseProgrammeJoinStatusCheck] =
    useLazyDemandResponseProgrammeJoinStatusCheckQuery();
  const [
    triggerWeeklyDemandResponseQuery,
    { isFetching: loadingViewWeeklyDemandResponseGuide },
  ] = useLazyViewWeeklyDemandControlActionsQuery();

  const startDate = weekRange[0];

  // useEffect(() => {
  //   setIsFirstTime(true);
  //   triggerWeeklyGuideQuery({
  //     startDate: moment(startDate).startOf("day").toISOString(true),
  //   })
  //     .unwrap()
  //     .then((res: ViewWeeklyUsageGuideResponseDTO) => {
  //       setUsageGuideResponse(res.weeklyPowerUsageGuides);
  //       setIsFirstTime(false);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, [triggerWeeklyGuideQuery, startDate]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     triggerWeeklyGuideQuery({
  //       startDate: moment(startDate).startOf("day").toISOString(true),
  //     });
  //     setIsFirstTime(false);
  //   }, 2000);

  //   return () => clearInterval(interval);
  // }, [triggerWeeklyGuideQuery, startDate]);

  useEffect(() => {
    triggerDemandResponseProgrammeJoinStatusCheck()
      .unwrap()
      .then((res: DemandResponseProgrammeJoinStatusCheckResponseDTO) => {
        if (!res.joined) {
          navigate(AppRoute.JOIN_DEMAND_RESPONSE_PROGRAM);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [triggerDemandResponseProgrammeJoinStatusCheck]);

  useEffect(() => {
    setIsFirstTime(true);
    triggerWeeklyDemandResponseQuery({
      startDate: moment(startDate).startOf("day").toISOString(true),
    })
      .unwrap()
      .then((res: ViewWeeklyDemandControlActionsResponseDTO) => {
        setDemandResponseProgramResponse(res.demandControlRequestViews);
        setIsFirstTime(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [triggerWeeklyDemandResponseQuery, startDate]);

  useEffect(() => {
    const interval = setInterval(() => {
      triggerWeeklyDemandResponseQuery({
        startDate: moment(startDate).startOf("day").toISOString(true),
      })
        .unwrap()
        .then((res: ViewWeeklyDemandControlActionsResponseDTO) => {
          setDemandResponseProgramResponse(res.demandControlRequestViews);
        })
        .catch((error) => {
          console.log(error);
        });
      setIsFirstTime(false);
    }, 2000);

    return () => clearInterval(interval);
  }, [triggerWeeklyDemandResponseQuery, startDate]);

  useEffect(() => {
    setWeekRange(
      getWeekRange(
        new Date(usageGuideFilterStore.selectedDate),
        usageGuideFilterStore.selectedDate
      )
    );
  }, [usageGuideFilterStore.selectedDate]);

  const headers: Header<Date>[] = [];

  for (let cIndex = 0; cIndex < 7; cIndex++) {
    headers.push({
      columnId: moment(weekRange[cIndex]).toDate(),
      title: moment(weekRange[cIndex]).format("DD"),
      value: (
        <div className="d-flex flex-column">
          <div>{moment(weekRange[cIndex]).format("ddd")}</div>
          <div
            className={`${
              moment(weekRange[cIndex]).format("YYYY-MM-DD") ===
              moment().format("YYYY-MM-DD")
                ? `today-grid-style`
                : ``
            } px-1`}
          >
            {moment(weekRange[cIndex]).format("DD")}
          </div>
        </div>
      ),
      column: cIndex + 1, // +1 for row header
      header: true,
      row: 0,
    });
  }

  let isCurrentWeek = false;

  for (const day of weekRange) {
    if (moment(day).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
      isCurrentWeek = true;
      break;
    }
  }

  const onCurrentTimeIndicatorRender = (elemId: string) => {
    if (isGoToCurrentTime) {
      scrollToView(elemId);
      setIsGoToCurrentTime(false);
    }
  };

  const isShowTimeBar = isCurrentWeek;

  const { calendarContent } = useTimeGrid<
    ColumnDataType,
    IDemandControlRequestView
  >(
    useMemo(() => {
      return {
        events,
        headers,
        isShowTimeBar,
        onCurrentTimeIndicatorRender,
        isDisableCreatingOverlay: true,
        viewEventModalTemplate: (events, onClose) => {
          if (events.length > 1) {
            return (
              <CreateModal<ColumnDataType>
                id=""
                events={events}
                onClose={onClose}
                onClick={(id) => {
                  setShowUsageGuideInfoModal(true);
                  setDemandControlRequestId(id);
                }}
              />
            );
          }
          return <></>;
        },
        viewEventOnClick: (events) => {
          if (events && events.length === 1) {
            setShowUsageGuideInfoModal(true);
            setDemandControlRequestId(events[0].data?.id || "");
          }
        },
        eventContentTemplate: (events) => {
          const renderOneEvent = (
            event: TimeGridEvent<ColumnDataType, IDemandControlRequestView>,
            isGrouped: boolean
          ) => (
            <>
              <Row>
                {!isGrouped && event.data?.demandControlRequestType && (
                  <Col className="col-auto">
                    <MaterialIcon
                      icon={
                        event.data?.demandControlRequestType ===
                        EDemandControlRequestType.DEMAND_CONTROL_LIMIT
                          ? "error"
                          : "release_alert"
                      }
                      color={
                        event.data?.demandControlRequestType ===
                        EDemandControlRequestType.DEMAND_CONTROL_LIMIT
                          ? "#E8C304"
                          : "#EC7575"
                      }
                      size={16}
                    />
                  </Col>
                )}
                <Col
                  className={`${
                    !isGrouped && event.data?.demandControlRequestType
                      ? "ps-0"
                      : ""
                  } ${
                    isGrouped ? `font-size-9` : `font-size-10`
                  } font-weight-500 text-white`}
                >
                  <div className={isGrouped ? `${styles.title} bg-gray-4` : ""}>
                    {event.title}
                  </div>
                </Col>
              </Row>
              {!isGrouped && (
                <Row className="font-size-10 font-weight-500 text-white">
                  <Col className="">
                    {powerConvertor(
                      event.data?.power || 0,
                      EPowerMeasureType.MEGA_WATT
                    )}{" "}
                    MW
                  </Col>
                </Row>
              )}
              {!isGrouped && (
                <Row>
                  <Col>
                    {moment()
                      .startOf("day")
                      .add(event.startTime, "minutes")
                      .format("HH:mm")}
                    -
                    {moment()
                      .startOf("day")
                      .add(event.endTime, "minutes")
                      .format("HH:mm")}
                  </Col>
                </Row>
              )}
            </>
          );
          return (
            <>
              {events?.length > 1 && (
                <Row>
                  <Col className="font-size-10 font-weight-500">
                    {events.length} Collapsed Guides
                  </Col>
                </Row>
              )}
              {events?.map((event, eventIndex) => {
                return (
                  <Row key={eventIndex}>
                    <Col>{renderOneEvent(event, events?.length > 1)}</Col>
                  </Row>
                );
              })}
            </>
          );
        },
      };
    }, [headers, events, isShowTimeBar])
  );

  useEffect(() => {
    if (demandResponseProgramResponse) {
      const groupByDateOrDevId: Record<
        number | string,
        DataColumn<Date, IDemandControlRequestView>
      > = {};
      demandResponseProgramResponse.forEach((drp) => {
        const fromDate = moment(new Date(drp.demandControlPeriod.fromDate));
        const toDate = moment(new Date(drp.demandControlPeriod.toDate));

        const day = fromDate.day();
        const columnId = fromDate.toDate().toISOString();
        const columnData = fromDate.toDate();

        const generated = {
          title: drp.title,
          columnData,
          startTime: fromDate
            .clone()
            .diff(fromDate.clone().startOf("day"), "minutes"),
          endTime: toDate
            .clone()
            .diff(toDate.clone().startOf("day"), "minutes"),
          date: fromDate.toDate(),
          data: drp,
        };

        if (generated) {
          // need to collect the event since event is generated
          if (!groupByDateOrDevId[day]) {
            // create a column if not exist
            groupByDateOrDevId[day] = {
              columnId,
              columnData,
              columnIndex: sundayStartToMondayStart(day),
              events: [],
            };
          }
          // then collect the event
          groupByDateOrDevId[day].events.push(generated);
        }
      });
      setEvents(Object.values(groupByDateOrDevId));
    } else {
      setEvents([]);
    }
  }, [demandResponseProgramResponse, startDate]);

  const onFilterChange = (date: Date, effectLevel: ELimitationType) => {
    dispatch(
      setUsageGuideFilter({
        effectLevel,
        selectedDate: moment(date).valueOf(),
      })
    );
  };

  const onClickThisWeekButton = () => {
    const currentDate = new Date();
    const currentWeekStartDate = moment(currentDate)
      .startOf("isoWeek")
      .toDate();
    onFilterChange(currentWeekStartDate, usageGuideFilterStore.effectLevel);
  };

  return (
    <div className="position-relative">
      {demandResponseProgramResponse && (
        <div className="container-white">
          <Row className="align-items-start">
            <Col>
              <Row className="align-items-center">
                <Col className="col-auto">
                  <AppDatePicker
                    selectedDate={new Date(usageGuideFilterStore.selectedDate)}
                    onChange={(date: Date) => {
                      onFilterChange(date, usageGuideFilterStore.effectLevel);
                    }}
                    isInput={true}
                    showDate={false}
                  />
                </Col>
                <Col className="col-auto">
                  <NavigateWeeks
                    selectedDate={new Date(usageGuideFilterStore.selectedDate)}
                    handleDateChange={(date: Date) => {
                      onFilterChange(date, usageGuideFilterStore.effectLevel);
                    }}
                  />
                </Col>
                <Col className="font-size-12 font-weight-500 text-light col-auto">
                  {getMonthRangeToDisplay(weekRange)}
                </Col>
                <Col className="col-sm-auto col-12 mt-3 mt-sm-0">
                  <AppButton
                    text="This Week"
                    size="medium"
                    onClick={onClickThisWeekButton}
                    className="font-size-12 font-weight-500 text-light py-1"
                    variant="transparentWithBorder"
                  />
                </Col>
                <Col className="col-sm-auto col-12 mt-3 mt-sm-0">
                  <AppButton
                    text="Now"
                    size="medium"
                    onClick={() => {
                      onClickThisWeekButton();
                      setIsGoToCurrentTime((ps) => !ps);
                    }}
                    className="font-size-12 font-weight-500 text-light py-1"
                    variant="transparentWithBorder"
                  />
                </Col>
              </Row>
            </Col>
            <Col className="col-12 col-sm-auto mt-3 mt-sm-0">
              <AppButton
                text="Devices Priority"
                size="medium"
                onClick={() => navigate(AppRoute.VIEW_DEMAND_RESPONSE_PROGRAM)}
                className="font-size-12 font-weight-500 text-light py-1"
                variant="transparentWithBorder"
              />
            </Col>
          </Row>
          <div className="mt-4">
            <Row>
              <Col className="grid-overflow-second px-2">{calendarContent}</Col>
            </Row>
          </div>
        </div>
      )}
      <SpinnerModal
        show={loadingViewWeeklyDemandResponseGuide && isFirstTime}
      />
      <UsageGuideInfoModal
        show={showUsageGuideInfoModal}
        onClose={() => setShowUsageGuideInfoModal(false)}
        demandControlRequestId={demandControlRequestId}
      />
    </div>
  );
};

export default UsageGuide;
