import { useState } from "react";
import RegistrationSteps from "../../shared/components/registration-steps/registration-steps";
import LoginLayout from "../../shared/oversight-core/shared-components/login-layout/login-layout";
import LoginForm from "./login-form/login-form";
import styles from "./login.module.scss";

const Login = () => {
  const [isLecoUser, setIsLecoUser] = useState<boolean>(true);

  return (
    <LoginLayout
      title="Login to Your Account"
      rightSideContent={isLecoUser ? <RegistrationSteps /> : undefined}
      wrapperClassName={isLecoUser ? styles.loginWrapperLeco : undefined}
    >
      <LoginForm isLecoUser={isLecoUser} setIsLecoUser={setIsLecoUser} />
    </LoginLayout>
  );
};

export default Login;
