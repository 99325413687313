import { Col, Row } from "react-bootstrap";
import styles from "./app-banner.module.scss";

interface IProps {
  content: string | React.ReactNode;
  bannerVariant: "yellow" | "lightBlue";
}

const AppBanner = (props: IProps) => {
  const { content, bannerVariant } = props;
  return (
    <Row>
      <Col className={`${styles.appBanner} ${styles[bannerVariant]} py-3`}>
        {content}
      </Col>
    </Row>
  );
};

export default AppBanner;
