import { Col, Row } from "react-bootstrap";
import MaterialIcon from "../material-icon/material-icon";
import styles from "./switch-leco-user.module.scss";

export interface ISwitchButton {
  buttonName: string;
  id: number;
}

interface IProps {
  buttons: ISwitchButton[];
  onChange: (id: number) => void;
  selectedButtonId: number;
}

const SwitchLecoUser = (props: IProps) => {
  const { buttons, onChange, selectedButtonId } = props;

  return (
    <>
      <Row>
        {buttons.map((button) => {
          return (
            <Col
              key={button.id}
              onClick={() => {
                onChange(button.id);
              }}
            >
              <div
                className={`${styles.switch} ${
                  selectedButtonId === button.id ? styles.purple : styles.white
                }`}
              >
                <Row key={button.id} className="align-items-center">
                  <Col className="col-auto pe-0">
                    <MaterialIcon icon="circle" size={12} />
                  </Col>
                  <Col className="ps-1">{button.buttonName}</Col>
                </Row>
              </div>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default SwitchLecoUser;
