import moment from "moment";
import { Col, Row } from "react-bootstrap";
import { EDemandControlRequestType } from "../../../../../../shared/oversight-core/enums/demand-control-request-type";
import { EPowerMeasureType } from "../../../../../../shared/oversight-core/enums/power-measure-type";
import { IDemandControlRequestView } from "../../../../../../shared/oversight-core/interfaces/demand-control-request-view";
import { TimeGridEvent } from "../../../../../../shared/oversight-core/shared-components/calendar/models";
import AppButton from "../../../../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import MaterialIcon from "../../../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import { powerConvertor } from "../../../../../../shared/oversight-core/utils/power-convertor";
import styles from "./create-modal.module.scss";

interface IProps<ColumnIdType, T> {
  id: string;
  events: TimeGridEvent<ColumnIdType, T>[];
  onClose: () => void;
  onClick: (id: string) => void;
}

const CreateModal = <Date,>(props: IProps<Date, IDemandControlRequestView>) => {
  const { events, onClose, onClick } = props;

  return (
    <div
      className={`${styles.container} ${
        events.length > 1 ? styles.containerBoxShadow : ""
      } py-3 px-1`}
    >
      <>
        {events.length > 1 && (
          <Row className="align-items-center px-4 pt-2">
            <Col className="font-size-16 font-weight-500 text-dark">
              {events.length} Guides
            </Col>
            <Col className="col-auto">
              <MaterialIcon
                icon="close"
                color="#383941"
                className="cursor-pointer"
                onClick={onClose}
              />
            </Col>
          </Row>
        )}
        <div className={styles.scrollableArea}>
          {events.map((event, eventIndex) => {
            return (
              <div
                key={eventIndex}
                className={`${events.length !== 1 ? "mt-4" : ""} px-4 py-2 ${
                  styles.eventContainer
                }`}
              >
                <Row>
                  <Col>
                    <MaterialIcon
                      icon={
                        event.data?.demandControlRequestType ===
                        EDemandControlRequestType.DEMAND_CONTROL_LIMIT
                          ? "error"
                          : "release_alert"
                      }
                      color={
                        event.data?.demandControlRequestType ===
                        EDemandControlRequestType.DEMAND_CONTROL_LIMIT
                          ? "#E8C304"
                          : "#EC7575"
                      }
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col className="font-size-16 font-weight-500 text-dark">
                    {event.title}
                  </Col>
                </Row>
                <Row>
                  <Col className="font-size-12 font-weight-500 text-light">
                    {`${
                      event.data?.demandControlDate
                        ? moment(event.data?.demandControlDate)
                            .add("days")
                            .format("ddd(DD), MMM, yyyy")
                        : ""
                    }`}
                  </Col>
                </Row>
                <Row className="mt-2 align-items-center">
                  <Col xs={`auto`} className="pe-0">
                    <MaterialIcon icon="schedule" color="#69768B" />
                  </Col>
                  <Col>
                    <Row>
                      <Col className="font-size-12 font-weight-400 text-light">
                        <div>
                          {moment(event.date)
                            .startOf("day")
                            .add(event.startTime, "minutes")
                            .format("HH:mm A")}{" "}
                          -{" "}
                          {moment(event.date)
                            .startOf("day")
                            .add(event.endTime, "minutes")
                            .format("HH:mm A")}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="justify-content-between mt-4">
                  <Col className="col-auto text-center">
                    <Row>
                      <Col className="text-light font-size-32 font-weight-600">
                        {powerConvertor(
                          event.data?.saving || 0,
                          EPowerMeasureType.MEGA_WATT
                        ).toFixed(2)}
                        MW
                      </Col>
                    </Row>
                    <Row>
                      <Col className="text-dark font-weight-400 font-size-14">
                        Saving
                      </Col>
                    </Row>
                  </Col>
                  <Col className="col-auto text-center">
                    <Row>
                      <Col className="text-light font-size-32 font-weight-600">
                        {event.data?.devices}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="text-dark font-weight-400 font-size-14">
                        Devices
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-4 mb-2">
                  <Col>
                    <AppButton
                      text="View"
                      size="medium"
                      className="font-size-12 font-weight-500"
                      onClick={() => {
                        if (event.data?.id) {
                          onClick(event.data?.id);
                        }
                      }}
                    />
                  </Col>
                </Row>
              </div>
            );
          })}
        </div>
      </>
    </div>
  );
};

export default CreateModal;
