import { Col, Row } from "react-bootstrap";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../../../shared/oversight-core/ui-elements/modal-container/modal-container";

interface IProps extends ModelContainerProps {
  show: boolean;
  deviceCount: number;
}

const ContinueModal = (props: IProps) => {
  const { show, deviceCount, ...rest } = props;

  return (
    <ModalContainer
      {...rest}
      title={"Thank You for Joining DRP"}
      show={show}
      size="modal-md"
      confirmButtonText="Continue"
      cancelButtonText="Cancel"
    >
      <>
        <Row>
          <Col className="font-weight-400 font-size-14 text-light">
            {deviceCount} Devices connected to DRP. We&apos;re thrilled to
            welcome you to our Demand Response program. Together, we can make a
            real difference!
          </Col>
        </Row>
      </>
    </ModalContainer>
  );
};

export default ContinueModal;
