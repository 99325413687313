import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  useUnlinkAcControllerMutation,
  useUnlinkedLinkPowerConsumerMutation,
  useUpdateAcControllerMutation,
  useUpdateControllerMutation,
} from "../../../redux/api/controller/controllerAPI";
import { useLazyCheckIfSmartPlugRegisteredToDRPQuery } from "../../../redux/api/usage-guide/usageGuideAPI";
import DeviceLinkModal from "../../../screens/dashboard/controllers/components/models/device-link-modal/device-link-modal";
import RemoveSmartControllerModal from "../../../screens/dashboard/controllers/components/models/remove-smart-controller-modal/remove-smart-controller-modal";
import DeviceUnlinkModal from "../../../screens/dashboard/space-clusters/components/models/device-unlink-modal/device-unlink-modal";
import CheckIfSmartPlugRegisteredResponseDTO from "../../oversight-core/dtos/response-dtos/check-if-smart-plug-registered-response-dto";
import { EActiveStatus } from "../../oversight-core/enums/active-status";
import { EConnectionStatus } from "../../oversight-core/enums/connection-status";
import { EDeviceStatus } from "../../oversight-core/enums/device-status";
import { EDeviceTypes } from "../../oversight-core/enums/device-types";
import { OvstErrorCode } from "../../oversight-core/enums/ovst-error-codes";
import { ESmartControllerType } from "../../oversight-core/enums/smart-controller-type";
import { AppRoute } from "../../oversight-core/interfaces/app-routes";
import ISmartController from "../../oversight-core/interfaces/smart-controller";
import { ISpaceClusterShallowHierarchyView } from "../../oversight-core/interfaces/space-hierarchy";
import ActiveInactiveIndicator from "../../oversight-core/ui-elements/active-inactive-indicator/active-inactive-indicator";
import AppDropDown from "../../oversight-core/ui-elements/app-dropdown/app-drop-down";
import AppToggle from "../../oversight-core/ui-elements/app-toggle/app-toggle";
import LastKnownStatus from "../../oversight-core/ui-elements/last-known-status/last-known-status";
import MaterialIcon from "../../oversight-core/ui-elements/material-icon/material-icon";
import SpinnerModal from "../../oversight-core/ui-elements/spinner/spinner";
import findIcon from "../../oversight-core/utils/findIcon";
import shortenedPath from "../../oversight-core/utils/shortenedPath";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../oversight-core/utils/toast";
import AcController from "../ac-controller/ac-controller";
import styles from "./controller-card.module.scss";

export interface IExtendedSmartDevice extends ISmartController {
  clusterId: string;
  spaceId: string;
}

interface IProps {
  smartDevice: IExtendedSmartDevice;
  path?: ISpaceClusterShallowHierarchyView;
  updateControllerStatus: (
    clusterId: string,
    spaceId: string,
    smartDeviceId: string,
    status: EDeviceStatus,
    controllerType: ESmartControllerType
  ) => void;
  setIsControllerUpdated?: React.Dispatch<React.SetStateAction<boolean>>;
  onAcTemperatureIncrease?: (
    spaceClusterId: string,
    spaceId: string,
    acControllerId: string,
    newTemperature: number
  ) => void;
  onAcTemperatureDecrease?: (
    spaceClusterId: string,
    spaceId: string,
    acControllerId: string,
    newTemperature: number
  ) => void;
  sendControllerInfoModalDetails: (
    controllerName: string,
    isToAnotherBillingSpace: boolean,
    spaceName: string,
    billingSpaceName?: string
  ) => void;
  deleteSmartController: (
    spaceClusterId: string,
    spaceId: string,
    smartDeviceId: string
  ) => void;
}

const ControllerCard = (props: IProps) => {
  const navigate = useNavigate();
  const {
    smartDevice,
    path,
    sendControllerInfoModalDetails,
    updateControllerStatus,
    onAcTemperatureDecrease,
    onAcTemperatureIncrease,
    deleteSmartController,
  } = props;
  const [isOn, setIsOn] = useState(false);
  const [breadCrumbPath, setBreadCrumbPath] = useState("");
  const [deviceUnlinkModal, setDeviceUnlinkModal] = useState(false);
  const [deviceLinkModal, setDeviceLinkModal] = useState(false);
  const [isDeviceUpdating, setIsDeviceUpdating] = useState(false);
  const [scheduleIdList, setScheduleIdList] = useState<string[]>([]);
  const [showRemoveSmartControllerModal, setShowRemoveSmartControllerModal] =
    useState(false);
  const [isEnableController, setIsEnableController] = useState(false);
  const [isRegisteredDRP, setIsRegisteredDRP] = useState(false);
  const [isRemoveDeviceFromDRP, setIsRemoveDeviceFromDRP] = useState(false);
  const [updateDRPRegisterStatus, setUpdateDRPRegisterStatus] = useState(false);

  const [updateController] = useUpdateControllerMutation();
  const [updateAcController] = useUpdateAcControllerMutation();
  const [unlinkedLinkPowerConsumer] = useUnlinkedLinkPowerConsumerMutation();
  const [unlinkAcController] = useUnlinkAcControllerMutation();

  const [checkIfSmartPlugRegisteredToDRP] =
    useLazyCheckIfSmartPlugRegisteredToDRPQuery();

  useEffect(() => {
    if (smartDevice.dynamicState?.powerState === EDeviceStatus.ON) {
      setIsOn(true);
    } else {
      setIsOn(false);
    }
  }, [smartDevice.dynamicState?.powerState]);

  useEffect(() => {
    if (smartDevice.activeStatus)
      setIsEnableController(smartDevice.activeStatus === EActiveStatus.ENABLED);
  }, [smartDevice.activeStatus]);

  useEffect(() => {
    if (updateDRPRegisterStatus) {
      checkSmartPlugRegisterToDRP(
        smartDevice.clusterId,
        smartDevice.spaceId,
        smartDevice.id
      );
    }
  }, [updateDRPRegisterStatus]);

  const checkSmartPlugRegisterToDRP = (
    spaceClusterId: string,
    spaceId: string,
    smartPlugId: string
  ) => {
    checkIfSmartPlugRegisteredToDRP({
      spaceClusterId: spaceClusterId,
      spaceId: spaceId,
      smartPlugId: smartPlugId,
    })
      .unwrap()
      .then((res: CheckIfSmartPlugRegisteredResponseDTO) => {
        setIsRegisteredDRP(res.registered);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    let paths = "";
    if (path) {
      path.ancestorSpaces
        .slice()
        .reverse()
        .forEach((ancestor, index) => {
          paths +=
            index !== path.ancestorSpaces.length - 1
              ? `${ancestor.name} > `
              : `${ancestor.name}`;
        });
    }
    setBreadCrumbPath(paths);
  }, [path]);

  const linkDevice = (
    spaceClusterId: string,
    spaceId: string,
    smartDeviceId: string,
    name: string,
    linkedPowerConsumerId: string,
    smartPlugType: string,
    spaceClusterIdOFDevice: string,
    spaceIdOfDevice: string,
    path: ISpaceClusterShallowHierarchyView
  ) => {
    setIsDeviceUpdating(true);
    if (smartDevice.smartDeviceType === ESmartControllerType.SMART_PLUG) {
      updateController({
        spaceClusterId: spaceClusterId,
        spaceId: spaceId,
        smartPlugId: smartDeviceId,
        name: name,
        linkedPowerConsumerId: linkedPowerConsumerId,
        smartPlugType: smartPlugType,
        isLinkOnly: true,
      })
        .unwrap()
        .then(() => {
          showSuccessMessage("Device linked Successfully");
          setIsDeviceUpdating(false);
          props.setIsControllerUpdated &&
            props.setIsControllerUpdated((ps) => !ps);

          if (spaceClusterId !== spaceClusterIdOFDevice) {
            sendControllerInfoModalDetails(
              smartDevice.name,
              true,
              path.ancestorSpaces[0].name,
              path.ancestorSpaces[path.ancestorSpaces.length - 1].name
            );
          } else if (spaceId !== spaceIdOfDevice) {
            sendControllerInfoModalDetails(
              smartDevice.name,
              false,
              path.ancestorSpaces[0].name,
              path.ancestorSpaces[path.ancestorSpaces.length - 1].name
            );
          }
        })
        .catch(() => {
          setIsDeviceUpdating(false);
          showErrorMessage("Device Linked Unsuccessful.");
        });

      return;
    }

    updateAcController({
      spaceClusterId: spaceClusterId,
      spaceId: spaceId,
      acControllerId: smartDeviceId,
      name: name,
      linkedAcId: linkedPowerConsumerId,
      isLinkOnly: true,
    })
      .unwrap()
      .then(() => {
        showSuccessMessage("Device linked Successfully");
        setIsDeviceUpdating(false);
        props.setIsControllerUpdated &&
          props.setIsControllerUpdated((ps) => !ps);
        if (spaceClusterId !== spaceClusterIdOFDevice) {
          sendControllerInfoModalDetails(
            smartDevice.name,
            true,
            path.ancestorSpaces[0].name,
            path.ancestorSpaces[path.ancestorSpaces.length - 1].name
          );
        } else if (spaceId !== spaceIdOfDevice) {
          sendControllerInfoModalDetails(
            smartDevice.name,
            false,
            path.ancestorSpaces[0].name,
            path.ancestorSpaces[path.ancestorSpaces.length - 1].name
          );
        }
      })
      .catch((error) => {
        setIsDeviceUpdating(false);
        if (
          error.status === 412 &&
          error.ovstErrorCode === OvstErrorCode.OVST_CONN_0003
        ) {
          showErrorMessage(
            "Failure in one of the services. Please try again later."
          );
          return;
        }
        showErrorMessage("Device Linked Unsuccessful.");
      });
  };

  const unlinkDevice = (
    spaceClusterId: string,
    spaceId: string,
    smartPlugId: string,
    linkedPowerConsumerId: string,
    excludedSemiAutomatedSchedules: string[]
  ) => {
    setIsDeviceUpdating(true);
    if (smartDevice.smartDeviceType === ESmartControllerType.AC_CONTROLLER) {
      unlinkAcController({
        spaceClusterId,
        spaceId,
        acControllerId: smartPlugId,
        linkedAcId: linkedPowerConsumerId,
        excludedSemiAutomatedSchedules,
      })
        .unwrap()
        .then(() => {
          showSuccessMessage("Device Unlinked Successfully");
          setIsDeviceUpdating(false);
          props.setIsControllerUpdated &&
            props.setIsControllerUpdated((ps) => !ps);
        })
        .catch(() => {
          setIsDeviceUpdating(false);
          showErrorMessage("Device Unlinked Unsuccessful.");
        });
      return;
    }

    unlinkedLinkPowerConsumer({
      spaceClusterId,
      spaceId,
      smartPlugId,
      linkedPowerConsumerId,
      excludedSemiAutomatedSchedules,
      keepInProgramme: isRemoveDeviceFromDRP,
    })
      .unwrap()
      .then(() => {
        showSuccessMessage("Device Unlinked Successfully");
        setIsDeviceUpdating(false);
        props.setIsControllerUpdated &&
          props.setIsControllerUpdated((ps) => !ps);
        setUpdateDRPRegisterStatus((ps) => !ps);
      })
      .catch(() => {
        setIsDeviceUpdating(false);
        showErrorMessage("Device Unlinked Unsuccessful.");
      });
  };

  const controllerCardDropDownItems = (smartDevice: IExtendedSmartDevice) => {
    const commonItems = [
      {
        text: "Edit Controller",
        onClick: () => {
          navigate(AppRoute.EDIT_CONTROLLER, {
            state: {
              smartDevice,
            },
          });
        },
      },
      {
        text: "Remove Controller",
        onClick: () => {
          checkSmartPlugRegisterToDRP(
            smartDevice.clusterId,
            smartDevice.spaceId,
            smartDevice.id
          );
          setShowRemoveSmartControllerModal(true);
        },
      },
      // {
      //   text: "Move Controller",
      //   onClick: () => {
      //     console.log();
      //   },
      // },
    ];

    if (smartDevice.activeStatus === EActiveStatus.ENABLED) {
      return [
        ...commonItems,
        // {
        //   text: "Disable Controller",
        //   onClick: () => {
        //     console.log();
        //   },
        // },
        // {
        //   text: "Automation",
        //   onClick: () => {
        //     console.log();
        //   },
        //   label: "Manual",
        // },
        // {
        //   text: "Share Access",
        //   onClick: () => {
        //     console.log();
        //   },
        // },
      ];
    } else {
      return [
        ...commonItems,
        // {
        //   text: "Share Access",
        //   onClick: () => {
        //     console.log();
        //   },
        // },
      ];
    }
  };

  const onIncrease = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (
      onAcTemperatureIncrease &&
      smartDevice &&
      smartDevice.dynamicState.temperature < 30 &&
      smartDevice.deviceConnectionState === EConnectionStatus.CONNECTED
    ) {
      onAcTemperatureIncrease(
        smartDevice.clusterId,
        smartDevice.spaceId,
        smartDevice.id,
        smartDevice.dynamicState?.temperature + 1
      );
    }
  };

  const onDecrease = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (
      onAcTemperatureDecrease &&
      smartDevice &&
      smartDevice.dynamicState.temperature > 16 &&
      smartDevice.deviceConnectionState === EConnectionStatus.CONNECTED
    ) {
      onAcTemperatureDecrease(
        smartDevice.clusterId,
        smartDevice.spaceId,
        smartDevice.id,
        smartDevice.dynamicState?.temperature - 1
      );
    }
  };

  const appToggle = (
    <AppToggle
      isOn={isOn}
      onSwitch={() => {
        if (
          smartDevice.deviceConnectionState === EConnectionStatus.CONNECTED &&
          smartDevice.activeStatus === EActiveStatus.ENABLED
        ) {
          updateControllerStatus(
            smartDevice.clusterId,
            smartDevice.spaceId,
            smartDevice.id,
            smartDevice.dynamicState?.powerState === EDeviceStatus.ON
              ? EDeviceStatus.OFF
              : EDeviceStatus.ON,
            smartDevice.smartDeviceType
          );
        }
      }}
      isDisable={
        smartDevice.deviceConnectionState === EConnectionStatus.DISCONNECTED ||
        smartDevice.activeStatus === EActiveStatus.DISABLED
      }
      size="sm"
    />
  );

  return (
    <>
      <div
        className={`${
          isEnableController
            ? styles.cardContainer
            : styles.disableCardContainer
        }`}
      >
        <Row className="align-items-center justify-content-between p-3 h-100">
          <Col
            className={`col-12 col-sm-6 col-lg-4 ${
              isEnableController ? `` : `opacity-50`
            }`}
          >
            <Row className="align-items-center">
              <Col className="col-auto text-dark font-size-14 font-weight-500">
                {smartDevice.name}
              </Col>
              <Col className="col-auto">
                <Row className="align-items-center">
                  <Col className="col-auto text-light font-size-12 font-weight-400 pe-0">
                    {smartDevice.serialKey}
                  </Col>
                  <Col>
                    <ActiveInactiveIndicator
                      isActive={
                        smartDevice.deviceConnectionState ===
                        EConnectionStatus.CONNECTED
                          ? true
                          : false
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className="text-light font-size-10 font-weight-400">
                {breadCrumbPath.length > 80
                  ? shortenedPath(breadCrumbPath)
                  : breadCrumbPath}
              </Col>
            </Row>
          </Col>
          <Col className="col-12 col-sm-5 col-lg-4 mt-3 mt-md-0 px-4 px-md-0">
            {isDeviceUpdating ? (
              <Row>
                <Col className="col-auto">
                  <SpinnerModal withOverlay={false} show={true} size="sm" />
                </Col>
              </Row>
            ) : (
              <>
                {!smartDevice.linkedPowerConsumer &&
                (!isEnableController ||
                  smartDevice.smartDeviceType ===
                    ESmartControllerType.AC_CONTROLLER) ? (
                  <Row className="align-items-center">
                    <Col
                      className="col-auto text-primary font-size-12 font-weight-500 cursor-pointer"
                      onClick={() => {
                        setDeviceLinkModal(true);
                      }}
                    >
                      Link Device
                    </Col>
                    <Col className="col-auto cursor-pointer ps-0">
                      <MaterialIcon
                        icon="link"
                        color="#2f2a89"
                        onClick={() => {
                          setDeviceLinkModal(true);
                        }}
                        size={22}
                      />
                    </Col>
                  </Row>
                ) : (
                  <Row className="align-items-center">
                    <Col
                      className={`${
                        smartDevice.linkedPowerConsumer
                          ? smartDevice.linkedPowerConsumer.deviceType
                          : styles.generalController
                      } p-1 rounded col-auto`}
                    >
                      <MaterialIcon
                        icon={
                          smartDevice.linkedPowerConsumer
                            ? findIcon(
                                smartDevice.linkedPowerConsumer
                                  .deviceType as EDeviceTypes
                              )
                            : "smart_outlet"
                        }
                      />
                    </Col>
                    <Col className="col-auto">
                      <Row className="align-items-center">
                        <Col
                          className={`col-auto ${
                            smartDevice.linkedPowerConsumer
                              ? "text-dark"
                              : "text-light"
                          } font-size-12 ${
                            smartDevice.linkedPowerConsumer
                              ? "font-weight-500"
                              : "font-weight-400"
                          }`}
                        >
                          {smartDevice.linkedPowerConsumer ? (
                            smartDevice.linkedPowerConsumer.name
                          ) : (
                            <>
                              General
                              <br />
                              Controller
                            </>
                          )}
                        </Col>
                      </Row>
                      {smartDevice.linkedPowerConsumer && (
                        <Row>
                          <Col className="col-auto text-light font-weight-400 font-size-10">
                            {smartDevice.linkedPowerConsumer.powerUsageInWatt}W
                          </Col>
                        </Row>
                      )}
                    </Col>
                    {smartDevice.linkedPowerConsumer && (
                      <Col className="col-auto ps-0 cursor-pointer">
                        <MaterialIcon
                          icon="link_off"
                          color="#2f2a89"
                          onClick={() => {
                            setDeviceUnlinkModal(true);
                          }}
                          size={22}
                        />
                      </Col>
                    )}
                  </Row>
                )}
              </>
            )}
          </Col>
          <Col className="col-12 col-lg-auto mt-3 mt-lg-0 px-4 px-lg-2">
            <Row className="align-items-center">
              <Col
                className={`col-auto ${
                  smartDevice.activeStatus === EActiveStatus.ENABLED &&
                  smartDevice.deviceConnectionState ===
                    EConnectionStatus.DISCONNECTED
                    ? `pe-0`
                    : ``
                }`}
              >
                <Row>
                  <Col
                    className={
                      smartDevice.deviceConnectionState ===
                        EConnectionStatus.DISCONNECTED ||
                      (smartDevice.deviceConnectionState ===
                        EConnectionStatus.CONNECTED &&
                        smartDevice.activeStatus === EActiveStatus.DISABLED)
                        ? `opacity-50`
                        : ``
                    }
                  >
                    {appToggle}
                  </Col>
                </Row>
                {smartDevice.deviceConnectionState ===
                  EConnectionStatus.DISCONNECTED &&
                  smartDevice.activeStatus === EActiveStatus.ENABLED && (
                    <LastKnownStatus />
                  )}
              </Col>
              <Col>
                <AppDropDown items={controllerCardDropDownItems(smartDevice)} />
              </Col>
            </Row>
          </Col>
        </Row>
        {smartDevice.smartDeviceType === ESmartControllerType.AC_CONTROLLER &&
          smartDevice.linkedPowerConsumer && (
            <Row className="mx-0">
              <Col className="mt-1 mb-2">
                <AcController
                  temperature={smartDevice.dynamicState?.temperature}
                  onIncrease={onIncrease}
                  onDecrease={onDecrease}
                  isShowTemperatureText={true}
                  connectionState={smartDevice.deviceConnectionState}
                />
              </Col>
            </Row>
          )}
      </div>
      <DeviceLinkModal
        show={deviceLinkModal}
        onClose={() => {
          setDeviceLinkModal(false);
        }}
        onCancel={() => {
          setDeviceLinkModal(false);
        }}
        serialNumber={smartDevice.serialKey}
        controllerName={smartDevice.name}
        sendSelectedDevice={(device) => {
          linkDevice(
            smartDevice.clusterId,
            smartDevice.spaceId,
            smartDevice.id,
            smartDevice.name,
            device.powerConsumer.id,
            smartDevice.smartPlugType,
            device.spaceClusterId,
            device.spaceId,
            device.path
          );
          setDeviceLinkModal(false);
        }}
        deviceConnectionState={smartDevice.deviceConnectionState}
        serialKeyDeviceType={smartDevice.smartDeviceType}
      />
      <DeviceUnlinkModal
        show={deviceUnlinkModal}
        deviceName={smartDevice.linkedPowerConsumer?.name || ""}
        controllerName={smartDevice.name}
        onCancel={() => setDeviceUnlinkModal(false)}
        onClose={() => setDeviceUnlinkModal(false)}
        onConfirm={() => {
          unlinkDevice(
            smartDevice.clusterId,
            smartDevice.spaceId,
            smartDevice?.id,
            smartDevice?.linkedPowerConsumer?.id || "",
            scheduleIdList
          );
          setDeviceUnlinkModal(false);
        }}
        spaceClusterId={smartDevice.clusterId}
        linkedPowerConsumerId={smartDevice.linkedPowerConsumer?.id || ""}
        setScheduleIdList={setScheduleIdList}
        smartDeviceType={smartDevice.smartDeviceType}
        isRegisteredDRP={isRegisteredDRP}
        isRemoveDeviceFromDRP={isRemoveDeviceFromDRP}
        setIsRemoveDeviceFromDRP={setIsRemoveDeviceFromDRP}
        smartPlugId={smartDevice.id}
      />
      <RemoveSmartControllerModal
        show={showRemoveSmartControllerModal}
        onClose={() => setShowRemoveSmartControllerModal(false)}
        onCancel={() => setShowRemoveSmartControllerModal(false)}
        onConfirm={() => {
          deleteSmartController(
            smartDevice.clusterId,
            smartDevice.spaceId,
            smartDevice.id
          );
          setShowRemoveSmartControllerModal(false);
        }}
        controllerName={smartDevice.name}
        smartDeviceType={smartDevice.smartDeviceType}
        spaceClusterId={smartDevice.clusterId}
        linkedPowerConsumerId={smartDevice.linkedPowerConsumer?.id || ""}
        smartPlugId={smartDevice.id}
      />
    </>
  );
};

export default ControllerCard;
