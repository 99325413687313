import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  useLazyGetSpaceQuery,
  useLazyGetSpacesQuery,
} from "../../../redux/api/space/spaceAPI";
import { selectBillingSpaceFilter } from "../../../redux/features/billing-space-filter/billing-space-filter-slice";
import {
  defaultSmartDevice,
  selectUsageFilter,
} from "../../../redux/features/usage-filter/usage-filter-slice";
import ControllerStatisticsResponseDTO from "../../oversight-core/dtos/response-dtos/controller-statistics-response-dto";
import { EActiveStatus } from "../../oversight-core/enums/active-status";
import { EConnectionStatus } from "../../oversight-core/enums/connection-status";
import { EDeviceTypes } from "../../oversight-core/enums/device-types";
import { ESmartControllerType } from "../../oversight-core/enums/smart-controller-type";
import { ISpaceView } from "../../oversight-core/interfaces/entities/space";
import { ISelectedSpace } from "../../oversight-core/interfaces/selected-space";
import ISmartController from "../../oversight-core/interfaces/smart-controller";
import FilteringSidePanel from "../../oversight-core/shared-components/filtering-side-panel/filtering-side-panel";
import ActiveInactiveIndicator from "../../oversight-core/ui-elements/active-inactive-indicator/active-inactive-indicator";
import AppSelect, {
  Option,
} from "../../oversight-core/ui-elements/app-select/app-select";
import AppSwitch from "../../oversight-core/ui-elements/app-switch/app-switch";
import ButtonWithBadge from "../../oversight-core/ui-elements/buttons/button-with-badge/button-with-badge";
import RealTimeAreaChart from "../../oversight-core/ui-elements/real-time-area-chart/realtime-area-chart";
import SpaceSelectorDropdown from "../../oversight-core/ui-elements/space-selector-dropdown/space-selector-dropdown";
import SpinnerModal from "../../oversight-core/ui-elements/spinner/spinner";
import getDeviceTypes, {
  getDeviceLabel,
} from "../../oversight-core/utils/getDeviceType";
import SelectedFiltersView from "../selected-filters-view/selected-filters-view";
import styles from "./real-time-power-usage-chart-view.module.scss";

interface IProps {
  statistics: ControllerStatisticsResponseDTO;
  spaceName: string;
  deviceName: string;
  deviceType: EDeviceTypes;
  predictedPowerUsageUnits: string;
  graphData: number[];
  labels: string[];
  onFilter: (
    selectedSpace: ISelectedSpace,
    selectedDeviceType: string,
    selectedDevice: { id: string; name: string },
    selectedSmartDevice: ISmartController,
    isApplyForChildSpaces: boolean,
    selectedEntireSpace: ISpaceView | undefined
  ) => void;
  isFetching: boolean;
}

const deviceTypes: Option[] = [
  { value: "", label: getDeviceLabel(EDeviceTypes.ALL_DEVICES, true) },
  ...getDeviceTypes(true),
];

const defaultDeviceType: Option = deviceTypes[0];

const RealTimePowerUsageChartView = (props: IProps) => {
  const { onFilter } = props;
  const usageFilterStore = useSelector(selectUsageFilter);
  const billingSpaceFilter = useSelector(selectBillingSpaceFilter);
  const [showFilter, setShowFilter] = useState(false);
  const [spaceClusters, setSpaceClusters] = useState<ISpaceView[]>([]);
  const [selectedSpace, setSelectedSpace] = useState<ISelectedSpace>({
    ...usageFilterStore.selectedSpace,
  });
  const [selectedDeviceType, setSelectedDeviceType] = useState<Option>({
    ...(deviceTypes.find(
      (deviceType) => deviceType.value === usageFilterStore.selectedDeviceType
    ) || defaultDeviceType),
  });
  const [selectedDevice, setSelectedDevice] = useState<Option>({
    label: "",
    value: "",
  });
  const [fetchedSpace, setFetchedSpace] = useState<ISpaceView>();
  const [selectedSmartDevice, setSelectedSmartDevice] =
    useState<ISmartController>();
  const [badgeValue, setBadgeValue] = useState(0);

  const [triggerGetSpace] = useLazyGetSpaceQuery();
  const [triggerGetSpaces] = useLazyGetSpacesQuery();
  const [isApplyForChildSpaces, setIsApplyForChildSpaces] = useState(
    usageFilterStore.isApplyForChildSpaces
  );

  useEffect(() => {
    if (selectedSpace?.clusterId && selectedSpace?.id) {
      if (selectedSpace.clusterId === billingSpaceFilter.spaceCluster.id) {
        if (selectedSpace.id === billingSpaceFilter.spaceCluster.rootSpace.id) {
          setIsApplyForChildSpaces(true);
        } else {
          setIsApplyForChildSpaces(false);
        }
      } else {
        setIsApplyForChildSpaces(true);
      }

      triggerGetSpace({
        clusterId: selectedSpace.clusterId,
        spaceId: selectedSpace.id,
      })
        .unwrap()
        .then((res) => {
          setFetchedSpace(res.subSpaceCluster.rootSpace);
          setSelectedDevice({
            label: "",
            value: "",
          });
        })
        .catch(() => {
          setFetchedSpace(undefined);
          setSelectedDevice({
            label: "",
            value: "",
          });
        });
    }
  }, [selectedSpace?.clusterId, selectedSpace?.id]);

  useEffect(() => {
    const smartDevice = findSmartDevices(
      fetchedSpace,
      isApplyForChildSpaces
    ).find((sd) => sd.data?.id === selectedDevice.value);

    setSelectedSmartDevice(smartDevice ? smartDevice.data : defaultSmartDevice);
  }, [fetchedSpace, selectedDevice.value, isApplyForChildSpaces]);

  const findSmartDevices = (
    space: ISpaceView | undefined,
    isApplyForChildSpaces: boolean
  ): Option[] => {
    if (!space) {
      return [];
    }
    const devicesListOne = space.smartDevices
      .filter((device) =>
        selectedDeviceType.value
          ? selectedDeviceType.value ===
              device.linkedPowerConsumer?.deviceCategory &&
            device.smartDeviceType === ESmartControllerType.SMART_PLUG
          : device.smartDeviceType === ESmartControllerType.SMART_PLUG
      )
      .map((smartDevice) => {
        return {
          label: `${smartDevice.name} ${
            smartDevice.linkedPowerConsumer ? "|" : ""
          } ${smartDevice.linkedPowerConsumer?.name || ""}`,
          value: smartDevice.id,
          data: smartDevice,
        };
      });

    if (!isApplyForChildSpaces || space.childSpaces.length === 0) {
      return devicesListOne;
    }

    const devicesListTwo: Option[] = [...devicesListOne];
    for (const cs of space.childSpaces) {
      devicesListTwo.push(...findSmartDevices(cs, isApplyForChildSpaces));
    }

    return devicesListTwo;
  };

  useEffect(() => {
    triggerGetSpaces()
      .unwrap()
      .then((response) => {
        const tempArray: ISpaceView[] = [];
        for (const spaceCluster of response.spaceClusters) {
          tempArray.push({
            ...spaceCluster.rootSpace,
            tariffCode: spaceCluster.serviceProviderAccount.tariffCode,
            accountNumber: spaceCluster.serviceProviderAccount.accountNumber,
            accountNumberLabel: spaceCluster.serviceProviderAccount.label,
            clusterId: spaceCluster.id,
          });
        }
        setSpaceClusters(tempArray);
      })
      .catch(() => {
        setSpaceClusters([]);
      });
  }, []);

  useEffect(() => {
    setSelectedSpace({ ...usageFilterStore.selectedSpace });
  }, [usageFilterStore.selectedSpace]);

  const checkDeviceConnectionState = (
    space: ISpaceView | undefined
  ): boolean => {
    if (space === undefined) {
      return false;
    }

    const smartControllerWithSpaceCluster = space.smartDevices.some(
      (smartDevice) =>
        smartDevice.deviceConnectionState === EConnectionStatus.CONNECTED &&
        smartDevice.smartDeviceType == ESmartControllerType.SMART_PLUG
    );

    if (
      smartControllerWithSpaceCluster ||
      !usageFilterStore.isApplyForChildSpaces
    ) {
      return smartControllerWithSpaceCluster;
    } else {
      const smartControllerWithChildSpace = space.childSpaces
        .map((childSpace) => {
          return checkDeviceConnectionState(childSpace);
        })
        .some((smartDevice) => smartDevice === true);

      return smartControllerWithChildSpace;
    }
  };

  useEffect(() => {
    if (
      (billingSpaceFilter.spaceCluster.id !==
        usageFilterStore.selectedSpace.clusterId ||
        billingSpaceFilter.spaceCluster.rootSpace.id !==
          usageFilterStore.selectedSpace.id) &&
      usageFilterStore.selectedDeviceType !== "" &&
      usageFilterStore.selectedDevice.id !== ""
    ) {
      setBadgeValue(3);
    } else if (
      ((billingSpaceFilter.spaceCluster.id !==
        usageFilterStore.selectedSpace.clusterId ||
        billingSpaceFilter.spaceCluster.rootSpace.id !==
          usageFilterStore.selectedSpace.id) &&
        usageFilterStore.selectedDeviceType !== "") ||
      ((billingSpaceFilter.spaceCluster.id !==
        usageFilterStore.selectedSpace.clusterId ||
        billingSpaceFilter.spaceCluster.rootSpace.id !==
          usageFilterStore.selectedSpace.id) &&
        usageFilterStore.selectedDevice.id !== "") ||
      (usageFilterStore.selectedDeviceType !== "" &&
        usageFilterStore.selectedDevice.id !== "")
    ) {
      setBadgeValue(2);
    } else if (
      billingSpaceFilter.spaceCluster.id !==
        usageFilterStore.selectedSpace.clusterId ||
      billingSpaceFilter.spaceCluster.rootSpace.id !==
        usageFilterStore.selectedSpace.id ||
      usageFilterStore.selectedDeviceType !== "" ||
      usageFilterStore.selectedDevice.id !== ""
    ) {
      setBadgeValue(1);
    } else {
      setBadgeValue(0);
    }
  }, [
    billingSpaceFilter.spaceCluster.id,
    billingSpaceFilter.spaceCluster.rootSpace.id,
    usageFilterStore.selectedDeviceType,
    usageFilterStore.selectedDevice.id,
    usageFilterStore.selectedSpace,
  ]);

  return (
    <div className={styles.realTimePowerUsageChart}>
      <Row className="align-items-center p-4">
        <Col>
          <Row className="align-items-center g-0 g-sm-4">
            <Col className="col-12 col-sm-auto">
              <Row className="align-items-center">
                <Col className="col-auto pe-0">
                  {(usageFilterStore.selectedDevice.id &&
                    usageFilterStore.selectedSmartDevice
                      .deviceConnectionState === EConnectionStatus.CONNECTED) ||
                  (!usageFilterStore.selectedDevice.id &&
                    checkDeviceConnectionState(
                      usageFilterStore.selectedEntireSpace
                    ) &&
                    props.graphData.length !== 0) ? (
                    <SpinnerModal
                      show={true}
                      withOverlay={false}
                      size="sm"
                      color="red"
                      speedMultiplier={0.6}
                    />
                  ) : (
                    <ActiveInactiveIndicator isActive={false} />
                  )}
                </Col>
                <Col>
                  <Row className="flex-column align-item-center">
                    <Col className="text-dark font-size-14 font-weight-500 col-sm-auto pe-4">
                      Live Power Usage
                    </Col>
                    {usageFilterStore.selectedDevice.id &&
                      usageFilterStore.selectedSmartDevice
                        .deviceConnectionState ===
                        EConnectionStatus.DISCONNECTED && (
                        <Col className="font-size-10 font-weight-400 text-light">
                          {usageFilterStore.selectedSmartDevice
                            .linkedPowerConsumer ||
                          usageFilterStore.selectedSmartDevice.activeStatus ===
                            EActiveStatus.ENABLED
                            ? `Disconnected`
                            : `Disabled`}{" "}
                          Smart Plug
                        </Col>
                      )}
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col className="col-12 col-sm-auto mt-4">
              <Row className="align-items-center g-3 g-sm-4">
                <Col className="text-dark font-size-24 font-weight-600 jakarta col-auto">
                  {props.isFetching ? (
                    <SpinnerModal
                      show={props.isFetching}
                      withOverlay={false}
                      size="sm"
                    />
                  ) : (
                    <>{props.statistics.latestPowerInWatt.toFixed(2)}W</>
                  )}
                </Col>
                <Col className="text-light font-weight-500 col-auto">
                  {props.isFetching ? (
                    <SpinnerModal
                      show={props.isFetching}
                      withOverlay={false}
                      size="sm"
                    />
                  ) : (
                    <Row className="flex-column">
                      <Col className="font-size-12">
                        {props.statistics.maxPowerInWatt?.toFixed(2)}W
                      </Col>
                      <Col className="font-size-10">Today&rsquo;s maximum</Col>
                    </Row>
                  )}
                </Col>
                <Col className="text-light font-weight-500 col-auto">
                  {props.isFetching ? (
                    <SpinnerModal
                      show={props.isFetching}
                      withOverlay={false}
                      size="sm"
                    />
                  ) : (
                    <Row className="flex-column">
                      <Col className="font-size-12">
                        {props.statistics.averagePowerInWatt?.toFixed(2)}W
                      </Col>
                      <Col className="font-size-10">Today&rsquo;s average</Col>
                    </Row>
                  )}
                </Col>
                <Col className="text-light font-weight-500 col-auto">
                  {props.isFetching ? (
                    <SpinnerModal
                      show={props.isFetching}
                      withOverlay={false}
                      size="sm"
                    />
                  ) : (
                    <Row className="flex-column">
                      <Col className="font-size-12">
                        {props.predictedPowerUsageUnits}
                      </Col>
                      <Col className="font-size-10">
                        Today&rsquo;s Prediction
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col className="col-12 col-xl-auto mt-4 mt-xl-0">
          <Row className="align-items-center justify-content-between justify-content-sm-start">
            <Col className="col-auto">
              <SelectedFiltersView
                spaceName={usageFilterStore.selectedSpace.name}
                filters={[
                  {
                    icon:
                      (usageFilterStore.selectedDeviceType as EDeviceTypes) ||
                      EDeviceTypes.ALL_DEVICES,
                    filterName: usageFilterStore.selectedSmartDevice.id
                      ? usageFilterStore.selectedSmartDevice.linkedPowerConsumer
                        ? usageFilterStore.selectedSmartDevice
                            .linkedPowerConsumer.name
                        : usageFilterStore.selectedSmartDevice.name
                      : usageFilterStore.selectedDeviceType || "All Devices",
                    subText: "Today",
                  },
                ]}
                isVisibleIcon={!usageFilterStore.selectedSmartDevice.id}
              />
            </Col>
            <Col className="col-auto">
              <ButtonWithBadge
                text="Filter"
                icon="filter_alt"
                onClick={() => {
                  setShowFilter(true);
                }}
                badgeValue={badgeValue}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <RealTimeAreaChart
        borderColor1={"#D84560"}
        borderColor2={"#D84560"}
        backgroundColor1={"rgba(204, 41, 41, 0.09)"}
        backgroundColor2={"rgba(204, 41, 41, 0.09)"}
        label1={"Usage Prediction"}
        labels={props.labels}
        yAxesUnit="W"
        data1={props.graphData}
        hasSecondDataSet={false}
        isFetching={props.isFetching}
        spaceName={usageFilterStore.selectedSpace.name}
        smartDeviceName={usageFilterStore.selectedSmartDevice.name || ""}
        allDevicesDisconnected={
          usageFilterStore.selectedDevice.id
            ? usageFilterStore.selectedSmartDevice.deviceConnectionState ===
              EConnectionStatus.DISCONNECTED
            : !checkDeviceConnectionState(usageFilterStore.selectedEntireSpace)
        }
        filterType={usageFilterStore.selectedDevice.id ? "Device" : "Space"}
      />
      <FilteringSidePanel
        isOpen={showFilter}
        onClose={() => {
          setShowFilter(false);
        }}
        onFilter={() => {
          onFilter(
            selectedSpace,
            selectedDeviceType.value,
            selectedDevice.data,
            selectedSmartDevice || defaultSmartDevice,
            isApplyForChildSpaces,
            fetchedSpace
          );
          setShowFilter(false);
        }}
        onClearAll={() => {
          onFilter(
            {
              clusterId: billingSpaceFilter.spaceCluster.id,
              id: billingSpaceFilter.spaceCluster.rootSpace.id,
              name: billingSpaceFilter.spaceCluster.label,
            },
            "",
            {
              id: "",
              name: "",
            },
            {
              ...defaultSmartDevice,
            },
            true,
            billingSpaceFilter.spaceCluster.rootSpace
          );
          setSelectedDeviceType(defaultDeviceType);
          setShowFilter(false);
          setIsApplyForChildSpaces(true);
          setSelectedDevice({
            label: "",
            value: "",
          });
        }}
      >
        <>
          <SpaceSelectorDropdown
            dropdownOptions={[
              ...spaceClusters
                .filter(
                  (space) =>
                    space.clusterId === usageFilterStore.selectedSpace.clusterId
                )
                .map((space) => {
                  return {
                    clusterId: space.clusterId,
                    id: space.id,
                    name: space.name,
                    childSpaces: space.childSpaces,
                  };
                }),
            ]}
            disabled={false}
            selectedSubSpace={selectedSpace?.name}
            updateSelectedSubSpaceId={(
              selectedSubSpaceId: string,
              selectedSubSpaceName: string,
              clusterId?: string
            ) => {
              setSelectedSpace({
                id: selectedSubSpaceId,
                name: selectedSubSpaceName,
                clusterId: clusterId || "",
              });
              setSelectedDevice({
                label: "",
                value: "",
              });
            }}
            label="Space"
            className="font-weight-500 text-light"
          />
          <Row className="align-items-center mt-3">
            <Col xs={"auto"} className="font-size-14">
              Apply for child spaces?
            </Col>
            <Col>
              <AppSwitch
                isOn={isApplyForChildSpaces}
                size="sm"
                onSwitch={() => setIsApplyForChildSpaces((ps) => !ps)}
              />
            </Col>
          </Row>
          <AppSelect
            label="Device Type"
            options={[...deviceTypes]}
            className="mt-4"
            selectedValue={selectedDeviceType}
            onChangeOption={(selectedDeviceTypeOption) => {
              setSelectedDeviceType(selectedDeviceTypeOption);
              setSelectedDevice({
                label: "",
                value: "",
              });
            }}
            fontSize="12px"
            labelFontSize="font-size-14"
            fontWeight="500"
            fontColor="#69768b"
          />
          <AppSelect
            label="Device"
            selectedValue={selectedDevice}
            options={findSmartDevices(fetchedSpace, isApplyForChildSpaces)}
            onChangeOption={(selectedDeviceOption) =>
              setSelectedDevice(selectedDeviceOption)
            }
            className="mt-4"
            fontSize="12px"
            labelFontSize="font-size-14"
            fontWeight="500"
            fontColor="#69768b"
          />
        </>
      </FilteringSidePanel>
    </div>
  );
};

export default RealTimePowerUsageChartView;
