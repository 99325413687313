import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { EConnectionStatus } from "../../oversight-core/enums/connection-status";
import { EDevicePriority } from "../../oversight-core/enums/device-priority";
import { EDeviceTypes } from "../../oversight-core/enums/device-types";
import { ISpaceClusterPowerConsumerWithPathView } from "../../oversight-core/interfaces/power-consumer-with-path-view";
import ActiveInactiveIndicator from "../../oversight-core/ui-elements/active-inactive-indicator/active-inactive-indicator";
import AppSelect, {
  Option,
} from "../../oversight-core/ui-elements/app-select/app-select";
import MaterialIcon from "../../oversight-core/ui-elements/material-icon/material-icon";
import findIcon from "../../oversight-core/utils/findIcon";
import shortenedPath from "../../oversight-core/utils/shortenedPath";
import styles from "./device-item-card-with-app-select.module.scss";

interface IProps {
  device: ISpaceClusterPowerConsumerWithPathView;
  setSelectedDeviceList?: React.Dispatch<
    React.SetStateAction<ISpaceClusterPowerConsumerWithPathView[]>
  >;
  selectedStep?: number;
  currentHighPriorityDeviceOrder?: number;
  currentLowPriorityDeviceOrder?: number;
  setCurrentHighPriorityDeviceOrder?: React.Dispatch<
    React.SetStateAction<number>
  >;
  setCurrentLowPriorityDeviceOrder?: React.Dispatch<
    React.SetStateAction<number>
  >;
}

const devicePriorityType = [
  {
    label: "Don't Add to DPR",
    value: "",
  },
  {
    label: "Low Priority",
    value: EDevicePriority.LOW_PRIORITY,
  },
  {
    label: "High priority",
    value: EDevicePriority.HIGH_PRIORITY,
  },
];

const DeviceItemCardWithAppSelect = (props: IProps) => {
  const {
    device,
    currentHighPriorityDeviceOrder = 0,
    currentLowPriorityDeviceOrder = 0,
    selectedStep,
    setSelectedDeviceList,
    setCurrentHighPriorityDeviceOrder,
    setCurrentLowPriorityDeviceOrder,
  } = props;

  const [path, setPath] = useState("");
  const [devicePriority, setDevicePriority] = useState<Option>(
    devicePriorityType[
      device.devicePriority
        ? device.devicePriority === EDevicePriority.LOW_PRIORITY
          ? 1
          : 2
        : 0
    ]
  );

  useEffect(() => {
    let paths = "";
    const ancestorSpaces = device.path.ancestorSpaces.slice().reverse();

    ancestorSpaces.forEach((ancestor, index) => {
      paths +=
        index !== ancestorSpaces.length - 1
          ? `${ancestor.name} > `
          : `${ancestor.name}`;
    });

    setPath(paths);
  }, [device]);

  return (
    <Row
      className={`${styles["device-item-card"]} ${
        selectedStep === 2 && styles.allScroll
      } p-3 align-items-center`}
    >
      <Col>
        <Row>
          <Col
            className={`col-auto ${
              device.deviceType ? device.deviceType : styles.smartOutlet
            } p-2 rounded`}
          >
            <MaterialIcon
              icon={
                device.deviceType
                  ? findIcon(device.deviceType as EDeviceTypes)
                  : `smart_outlet`
              }
            />
          </Col>
          <Col>
            <Row className="align-items-center">
              <Col className="col-auto text-dark font-size-14 font-weight-500 pe-0">
                {device.name ? device.name : device.smartDeviceName}
              </Col>
              <Col>
                <Row className="align-items-center">
                  <Col className="col-auto font-size-12 text-light font-weight-400 pe-0">
                    {device.powerUsageInWatt}W
                  </Col>
                  {device.deviceConnectionStatus && (
                    <Col className="ps-1">
                      {
                        <ActiveInactiveIndicator
                          isActive={
                            device.deviceConnectionStatus ===
                            EConnectionStatus.CONNECTED
                              ? true
                              : false
                          }
                        />
                      }
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className="text-light font-size-10 font-weight-400">
                {path.length > 80 ? shortenedPath(path) : path}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      {selectedStep === 1 ? (
        <Col className="col-6 col-lg-5 col-xl-4 col-xxl-3">
          <AppSelect
            options={devicePriorityType}
            selectedValue={devicePriority}
            onChangeOption={(selectedOption) => {
              let isChanged = false;
              let previousPriority;

              setDevicePriority(selectedOption);
              setSelectedDeviceList &&
                setSelectedDeviceList((prevSelectedDeviceList) => {
                  const isFound = prevSelectedDeviceList.find(
                    (psd) => psd.smartDeviceId === device.smartDeviceId
                  );

                  if (isFound) {
                    previousPriority = isFound.devicePriority;

                    isChanged =
                      isFound.devicePriority !==
                      (selectedOption.value as EDevicePriority);

                    if (selectedOption.value === "") {
                      const filteredDevices = prevSelectedDeviceList.filter(
                        (psd) => psd.smartDeviceId !== device.smartDeviceId
                      );

                      const highPriorityDevices = filteredDevices.filter(
                        (device) =>
                          device.devicePriority ===
                          EDevicePriority.HIGH_PRIORITY
                      );
                      const lowPriorityDevices = filteredDevices.filter(
                        (device) =>
                          device.devicePriority === EDevicePriority.LOW_PRIORITY
                      );

                      if (previousPriority === EDevicePriority.HIGH_PRIORITY) {
                        const updatedHighPriorityDevices =
                          highPriorityDevices.map((device, index) => {
                            return { ...device, deviceOrder: index + 1 };
                          });

                        return [
                          ...updatedHighPriorityDevices,
                          ...lowPriorityDevices,
                        ];
                      }

                      if (previousPriority === EDevicePriority.LOW_PRIORITY) {
                        const updatedLowPriorityDevices =
                          lowPriorityDevices.map((device, index) => {
                            return { ...device, deviceOrder: index + 1 };
                          });

                        return [
                          ...highPriorityDevices,
                          ...updatedLowPriorityDevices,
                        ];
                      }
                    }

                    isFound.devicePriority =
                      selectedOption.value as EDevicePriority;
                    isFound.deviceOrder =
                      (selectedOption.value as EDevicePriority) ===
                      EDevicePriority.HIGH_PRIORITY
                        ? currentHighPriorityDeviceOrder + 1
                        : (selectedOption.value as EDevicePriority) ===
                          EDevicePriority.LOW_PRIORITY
                        ? currentLowPriorityDeviceOrder + 1
                        : 0;
                    return prevSelectedDeviceList;
                  } else {
                    return [
                      ...prevSelectedDeviceList,
                      {
                        ...device,
                        devicePriority: selectedOption.value as EDevicePriority,
                        deviceOrder:
                          (selectedOption.value as EDevicePriority) ===
                          EDevicePriority.HIGH_PRIORITY
                            ? currentHighPriorityDeviceOrder + 1
                            : (selectedOption.value as EDevicePriority) ===
                              EDevicePriority.LOW_PRIORITY
                            ? currentLowPriorityDeviceOrder + 1
                            : 0,
                      },
                    ];
                  }
                });

              if (isChanged) {
                if (
                  previousPriority === EDevicePriority.HIGH_PRIORITY &&
                  setCurrentHighPriorityDeviceOrder
                ) {
                  setCurrentHighPriorityDeviceOrder((ps) => ps - 1);
                }

                if (
                  previousPriority === EDevicePriority.LOW_PRIORITY &&
                  setCurrentLowPriorityDeviceOrder
                ) {
                  setCurrentLowPriorityDeviceOrder((ps) => ps - 1);
                }
              }

              if (
                (selectedOption.value as EDevicePriority) ===
                  EDevicePriority.HIGH_PRIORITY &&
                setCurrentHighPriorityDeviceOrder
              ) {
                setCurrentHighPriorityDeviceOrder((ps) => ps + 1);
                return;
              }

              if (
                (selectedOption.value as EDevicePriority) ===
                  EDevicePriority.LOW_PRIORITY &&
                setCurrentLowPriorityDeviceOrder
              ) {
                setCurrentLowPriorityDeviceOrder((ps) => ps + 1);
                return;
              }
            }}
            fontSize="12"
            fontWeight="400"
            fontColor="#69768B"
          />
        </Col>
      ) : (
        selectedStep === 2 && (
          <Col className="col-auto">
            <MaterialIcon
              icon="drag_pan"
              size={20}
              color="#69768B"
              className={styles.allScroll}
            />
          </Col>
        )
      )}
    </Row>
  );
};

export default DeviceItemCardWithAppSelect;
