import { Col, Row } from "react-bootstrap";
import styles from "./steps-number.module.scss";

interface ISteps {
  id: number;
  stepName: string;
}

interface IProps {
  steps: ISteps[];
  selectedStep: number;
}

const StepsNumber = (props: IProps) => {
  return (
    <Row>
      {props.steps.map((step, index) => (
        <Col
          key={index}
          className={`col-auto ${
            props.selectedStep === step.id ? styles.selected : styles.unselected
          }`}
        >
          <Row className="align-items-center">
            <Col className="col-auto pe-0">
              <div className={styles.circle}>{step.id}</div>
            </Col>
            <Col className={`${styles.stepName} font-size-14 font-weight-500`}>
              {step.stepName}
            </Col>
            {index - 1 ? <Col className={`${styles.dash} col-auto`}></Col> : ``}
          </Row>
        </Col>
      ))}
    </Row>
  );
};

export default StepsNumber;
