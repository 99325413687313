import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useLazyViewDemandResponseProgrammeQuery } from "../../../../../redux/api/usage-guide/usageGuideAPI";
import DeviceItemCardWithAppSelect from "../../../../../shared/components/device-item-card-with-app-select/device-item-card-with-app-select";
import ViewDemandResponseProgrammeResponseDTO from "../../../../../shared/oversight-core/dtos/response-dtos/view-demand-response-programme-response-dto";
import { EDevicePriority } from "../../../../../shared/oversight-core/enums/device-priority";
import { EDeviceTypes } from "../../../../../shared/oversight-core/enums/device-types";
import { AppRoute } from "../../../../../shared/oversight-core/interfaces/app-routes";
import { IDRPSpaceClusterSmartDeviceView } from "../../../../../shared/oversight-core/interfaces/drp-space-cluster-smart-device-view";
import { ISpaceClusterPowerConsumerWithPathView } from "../../../../../shared/oversight-core/interfaces/power-consumer-with-path-view";
import ArrowButton from "../../../../../shared/oversight-core/ui-elements/arrow-button/arrow-button";
import MaterialIcon from "../../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import SpinnerModal from "../../../../../shared/oversight-core/ui-elements/spinner/spinner";

const ViewDemandResponseProgram = () => {
  const navigate = useNavigate();

  const [demandResponseProgram, setDemandResponseProgram] = useState<
    IDRPSpaceClusterSmartDeviceView[]
  >([]);
  const [deviceList, setDeviceList] = useState<
    ISpaceClusterPowerConsumerWithPathView[]
  >([]);

  const [
    triggerDemandResponseProgram,
    { isFetching: isFetchingDemandResponseProgram },
  ] = useLazyViewDemandResponseProgrammeQuery();

  useEffect(() => {
    triggerDemandResponseProgram()
      .unwrap()
      .then((res: ViewDemandResponseProgrammeResponseDTO) => {
        setDemandResponseProgram(res.demandResponseProgramme.devices);
      })
      .catch(() => {
        setDemandResponseProgram([]);
      });
  }, [triggerDemandResponseProgram]);

  useEffect(() => {
    if (demandResponseProgram) {
      const devices: ISpaceClusterPowerConsumerWithPathView[] =
        demandResponseProgram?.map((drp) => {
          const linkedPowerConsumer = drp.smartDevice.linkedPowerConsumer;
          return {
            id: linkedPowerConsumer?.id || "",
            name: linkedPowerConsumer?.name || "",
            deviceType: linkedPowerConsumer?.deviceType as EDeviceTypes,
            deviceCategory: linkedPowerConsumer?.deviceCategory as EDeviceTypes,
            powerUsageInWatt: linkedPowerConsumer?.powerUsageInWatt || 0,
            path: drp.path,
            smartDeviceId: drp.smartDevice.id,
            smartDeviceName: drp.smartDevice.name,
            spaceClusterId: drp.spaceClusterId,
            deviceConnectionStatus: drp.smartDevice.deviceConnectionState,
            deviceOrder: drp.deviceOrder,
            devicePriority: drp.devicePriority as EDevicePriority,
          };
        });

      setDeviceList([...devices]);
    }
  }, [demandResponseProgram]);

  return (
    <>
      <div className="container-white">
        <Row className="align-items-center">
          <Col>
            <Row>
              <Col className="col-auto">
                <ArrowButton
                  onBack={() => navigate(AppRoute.LIMITATION_USAGE_GUIDE)}
                />
              </Col>
              <Col className="mt-1">
                <Row>
                  <Col className="text-dark font-weight-500 font-size-16">
                    Devices Priority for Demand Response Program
                  </Col>
                </Row>
                <Row>
                  <Col className="text-light font-weight-400 font-size-14">
                    Shows the device priority in descending order.
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col className="col-auto mt-4 mt-xl-0">
            <MaterialIcon
              onClick={() =>
                navigate(AppRoute.EDIT_DEMAND_RESPONSE_PROGRAM, {
                  state: {
                    devices: deviceList,
                  },
                })
              }
              icon="edit"
              className="cursor-pointer"
            />
          </Col>
        </Row>
      </div>
      <div className="position-relative">
        <Row className="mt-4">
          <Col className="text-dark font-weight-500">High Priority Devices</Col>
        </Row>
        {deviceList.filter(
          (dl) => dl.devicePriority === EDevicePriority.HIGH_PRIORITY
        ).length > 0 ? (
          <Row className="mt-1 mx-0">
            <Col>
              {deviceList
                .filter(
                  (dl) => dl.devicePriority === EDevicePriority.HIGH_PRIORITY
                )
                .map((d, index) => {
                  return (
                    <Row key={index} className="mt-1">
                      <Col>
                        <DeviceItemCardWithAppSelect device={d} />
                      </Col>
                    </Row>
                  );
                })}
            </Col>
          </Row>
        ) : (
          <div className="container-dash my-2 text-dark font-size-14 font-weight-400">
            There are no High Priority Devices.
          </div>
        )}
        <Row className="mt-4">
          <Col className="text-dark font-weight-500">Low Priority Devices</Col>
        </Row>
        {deviceList.filter(
          (dl) => dl.devicePriority === EDevicePriority.LOW_PRIORITY
        ).length > 0 ? (
          <Row className="mt-1 mx-0">
            <Col>
              {deviceList
                .filter(
                  (dl) => dl.devicePriority === EDevicePriority.LOW_PRIORITY
                )
                .map((d, index) => {
                  return (
                    <Row key={index} className="mt-1">
                      <Col>
                        <DeviceItemCardWithAppSelect device={d} />
                      </Col>
                    </Row>
                  );
                })}
            </Col>
          </Row>
        ) : (
          <div className="container-dash my-2 text-dark font-size-14 font-weight-400">
            There are no Low Priority Devices.
          </div>
        )}

        <SpinnerModal show={isFetchingDemandResponseProgram} />
      </div>
    </>
  );
};

export default ViewDemandResponseProgram;
