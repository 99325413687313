import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { EDeviceTypes } from "../../oversight-core/enums/device-types";
import { IPowerConsumerWithPathView } from "../../oversight-core/interfaces/power-consumer-with-path-view";
import MaterialIcon from "../../oversight-core/ui-elements/material-icon/material-icon";
import findIcon from "../../oversight-core/utils/findIcon";
import shortenedPath from "../../oversight-core/utils/shortenedPath";
import styles from "./device-item-card.module.scss";

interface IProps {
  device: IPowerConsumerWithPathView;
}

const DeviceItemCard = (props: IProps) => {
  const { device } = props;

  const [path, setPath] = useState("");

  useEffect(() => {
    let paths = "";
    const ancestorSpaces = device.path.ancestorSpaces.slice().reverse();

    ancestorSpaces.forEach((ancestor, index) => {
      paths +=
        index !== ancestorSpaces.length - 1
          ? `${ancestor.name} > `
          : `${ancestor.name}`;
    });

    setPath(paths);
  }, [device]);

  return (
    <Row className={`${styles["device-item-card"]} p-2 align-items-center`}>
      <Col className={`col-auto ${device.deviceType} p-2 rounded`}>
        <MaterialIcon icon={findIcon(device.deviceType as EDeviceTypes)} />
      </Col>
      <Col>
        <Row className="align-items-center">
          <Col className="text-dark font-size-14 font-weight-500">
            {device.name}
          </Col>
        </Row>
        <Row>
          <Col className="text-light font-size-10 font-weight-400">
            {path.length > 80 ? shortenedPath(path) : path}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default DeviceItemCard;
