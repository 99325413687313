import {
  SpaceClustersResponseDTO,
  ViewConsumedSpaceClusterByYearAndMonthResponseDTO,
  ViewScheduledPowerConsumersSortedByEnergyResponseDTO,
  ViewSpaceClusterConsumptionByYearAndMonthResponseDTO,
  ViewSpaceClusterEnergyWithComparisonResponseDTO,
} from "../../../shared/oversight-core/dtos/response-dtos/dashboard-api-dto";
import { ESortOrder } from "../../../shared/oversight-core/enums/sort-order";
import { transformErrorResponse } from "../../../shared/oversight-core/utils/transformErrorResponse";
import { rootApi } from "../apiManager";

export const dashboardAPI = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getSpaceClustersXY: builder.query<SpaceClustersResponseDTO, void>({
      transformErrorResponse,
      query() {
        return {
          url: `space-cluster/with-power-generators/list`,
          method: "GET",
        };
      },
    }),
    getSpaceClusterEnergyWithComparison: builder.query<
      ViewSpaceClusterEnergyWithComparisonResponseDTO,
      { spaceClusterId: string; zoneOffset: string }
    >({
      transformErrorResponse,
      query({ spaceClusterId, zoneOffset }) {
        return {
          url: `space-cluster/${spaceClusterId}/energy/with-comparison/view`,
          method: "GET",
          params: {
            zoneOffset,
          },
        };
      },
    }),
    getConsumedSpaceClusterByYearAndMonth: builder.query<
      ViewConsumedSpaceClusterByYearAndMonthResponseDTO,
      { spaceClusterId: string; year: number; month: number }
    >({
      transformErrorResponse,
      query({ spaceClusterId, year, month }) {
        return {
          url: `space-cluster/${spaceClusterId}/consumed-energy/by-year-month/view`,
          method: "GET",
          params: { year, month },
        };
      },
    }),
    viewPowerConsumersWithHighConsumption: builder.query<
      ViewScheduledPowerConsumersSortedByEnergyResponseDTO,
      {
        clusterId: string;
        sortingOrder: ESortOrder;
        limit: number;
      }
    >({
      transformErrorResponse,
      query({ clusterId, sortingOrder, limit }) {
        return {
          url: `space-cluster/${clusterId}/power-consumer-usage-schedule/sort-by-energy/view`,
          method: "GET",
          params: { sortingOrder, limit },
        };
      },
    }),
    viewSpaceClusterConsumptionByYearAndMonth: builder.query<
      ViewSpaceClusterConsumptionByYearAndMonthResponseDTO,
      { spaceClusterId: string; year: number; month: number }
    >({
      transformErrorResponse,
      query({ spaceClusterId, year, month }) {
        return {
          url: `space-cluster/${spaceClusterId}/consumption/by-year-month/view`,
          method: "GET",
          params: { year, month },
        };
      },
    }),
  }),

  overrideExisting: false,
});

export const {
  useLazyGetSpaceClustersXYQuery,
  useLazyGetSpaceClusterEnergyWithComparisonQuery,
  useLazyGetConsumedSpaceClusterByYearAndMonthQuery,
  useLazyViewPowerConsumersWithHighConsumptionQuery,
  useLazyViewSpaceClusterConsumptionByYearAndMonthQuery,
} = dashboardAPI;
