import { useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DropResult,
  Droppable,
  DroppableProvided,
} from "react-beautiful-dnd";
import { Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useLazyListSmartControllersQuery } from "../../../../../redux/api/controller/controllerAPI";
import {
  useJoinDemandResponseProgrammeMutation,
  useUpdateDemandResponseProgrammeMutation,
} from "../../../../../redux/api/usage-guide/usageGuideAPI";
import DeviceItemCardWithAppSelect from "../../../../../shared/components/device-item-card-with-app-select/device-item-card-with-app-select";
import { IListSmartControllersResponseDTO } from "../../../../../shared/oversight-core/dtos/response-dtos/list-smart-controllers-response-dto";
import { EDevicePriority } from "../../../../../shared/oversight-core/enums/device-priority";
import { EDeviceTypes } from "../../../../../shared/oversight-core/enums/device-types";
import { ESmartControllerType } from "../../../../../shared/oversight-core/enums/smart-controller-type";
import { AppRoute } from "../../../../../shared/oversight-core/interfaces/app-routes";
import { ISpaceClusterPowerConsumerWithPathView } from "../../../../../shared/oversight-core/interfaces/power-consumer-with-path-view";
import { ISelectedDevice } from "../../../../../shared/oversight-core/interfaces/selected-device";
import InfoModal from "../../../../../shared/oversight-core/shared-components/info-modal/info-modal";
import AppBannerWithIcon from "../../../../../shared/oversight-core/ui-elements/app-banner-with-icon/app-banner-with-icon";
import AppButton from "../../../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import SpinnerModal from "../../../../../shared/oversight-core/ui-elements/spinner/spinner";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../../shared/oversight-core/utils/toast";
import JoinDrpHeader from "../join-drp-header/join-drp-header";
import ContinueModal from "../modals/continue-modal/continue-modal";

const JoinDemandResponseProgram = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state;

  const [selectedPage, setSelectedPage] = useState(1);
  const [selectedStep, setSelectedStep] = useState(1);
  const [deviceList, setDeviceList] = useState<
    ISpaceClusterPowerConsumerWithPathView[]
  >([]);
  const [selectedDeviceList, setSelectedDeviceList] = useState<
    ISpaceClusterPowerConsumerWithPathView[]
  >([]);
  const [showContinueModal, setShowContinueModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [currentHighPriorityDeviceOrder, setCurrentHighPriorityDeviceOrder] =
    useState(0);
  const [currentLowPriorityDeviceOrder, setCurrentLowPriorityDeviceOrder] =
    useState(0);
  const [isEdit, setIsEdit] = useState(false);

  const [
    triggerListSmartControllers,
    { isFetching: isFetchingListSmartControllers },
  ] = useLazyListSmartControllersQuery();

  const [joinDemandResponseProgramme] =
    useJoinDemandResponseProgrammeMutation();
  const [updateDemandResponseProgramme] =
    useUpdateDemandResponseProgrammeMutation();

  useEffect(() => {
    triggerListSmartControllers({
      pageNumber: 0,
      pageSize: 1000,
      ascending: true,
      smartDeviceType: ESmartControllerType.SMART_PLUG,
      excludeGeneral: true,
      excludeDisabled: true,
    })
      .unwrap()
      .then((response: IListSmartControllersResponseDTO) => {
        const devices: ISpaceClusterPowerConsumerWithPathView[] =
          response.smartDevices.elements.map((s) => {
            const linkedPowerConsumer = s.smartDevice.linkedPowerConsumer;
            return {
              id: linkedPowerConsumer?.id || "",
              name: linkedPowerConsumer?.name || "",
              deviceType: linkedPowerConsumer?.deviceType as EDeviceTypes,
              deviceCategory:
                linkedPowerConsumer?.deviceCategory as EDeviceTypes,
              powerUsageInWatt: linkedPowerConsumer?.powerUsageInWatt || 0,
              path: s.path,
              smartDeviceId: s.smartDevice.id,
              smartDeviceName: s.smartDevice.name,
              spaceClusterId: s.spaceClusterId,
              deviceConnectionStatus: s.smartDevice.deviceConnectionState,
              deviceOrder: 0,
            };
          });
        setDeviceList([...devices]);
      })
      .catch(() => {
        setDeviceList([]);
      });
  }, [triggerListSmartControllers]);

  useEffect(() => {
    if (locationState?.devices) {
      setSelectedPage(2);
      setIsEdit(true);
      setSelectedDeviceList(locationState?.devices);
    }
  }, [locationState?.devices]);

  const highPriorityOnDragEnd = (result: DropResult) => {
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    let highPriorityDevices = selectedDeviceList.filter(
      (device) => device.devicePriority === EDevicePriority.HIGH_PRIORITY
    );
    const lowPriorityDevices = selectedDeviceList.filter(
      (device) => device.devicePriority === EDevicePriority.LOW_PRIORITY
    );

    const sourceDevice = highPriorityDevices[result.source.index];
    highPriorityDevices = highPriorityDevices.filter(
      (device, index) => index !== result.source.index
    );
    highPriorityDevices.splice(result.destination.index, 0, sourceDevice);

    const updatedHighPriorityDevices = highPriorityDevices.map(
      (device, index) => {
        return { ...device, deviceOrder: index + 1 };
      }
    );

    setSelectedDeviceList([
      ...updatedHighPriorityDevices,
      ...lowPriorityDevices,
    ]);
  };

  const lowPriorityOnDragEnd = (result: DropResult) => {
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    const highPriorityDevices = selectedDeviceList.filter(
      (device) => device.devicePriority === EDevicePriority.HIGH_PRIORITY
    );
    let lowPriorityDevices = selectedDeviceList.filter(
      (device) => device.devicePriority === EDevicePriority.LOW_PRIORITY
    );

    const sourceDevice = lowPriorityDevices[result.source.index];
    lowPriorityDevices = lowPriorityDevices.filter(
      (device, index) => index !== result.source.index
    );
    lowPriorityDevices.splice(result.destination.index, 0, sourceDevice);

    const updatedLowPriorityDevices = lowPriorityDevices.map(
      (device, index) => {
        return { ...device, deviceOrder: index + 1 };
      }
    );

    setSelectedDeviceList([
      ...highPriorityDevices,
      ...updatedLowPriorityDevices,
    ]);
  };

  const onSubmit = () => {
    const device: ISelectedDevice[] = selectedDeviceList.map((sd) => {
      return {
        spaceClusterId: sd.spaceClusterId,
        powerConsumerId: sd.id ? sd.id : null,
        smartDeviceId: sd.smartDeviceId,
        devicePriority: sd.devicePriority as EDevicePriority,
        deviceOrder: sd.deviceOrder,
      };
    });

    (isEdit ? updateDemandResponseProgramme : joinDemandResponseProgramme)({
      devices: [...device],
    })
      .unwrap()
      .then(() => {
        showSuccessMessage(
          `Demand Response Program ${
            locationState?.devices ? `Update` : `Join`
          } Successfully`
        );
        navigate(AppRoute.LIMITATION_USAGE_GUIDE);
      })
      .catch(() => {
        showErrorMessage(
          `Demand Response Program ${
            locationState?.devices ? `Update` : `Join`
          } Unsuccessfully`
        );
      });
  };

  return (
    <div className="position-relative">
      {selectedPage === 1 ? (
        <div className="container-white">
          <Row>
            <Col className="text-center text-dark font-weight-600 font-size-24">
              Join Demand Response Program
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="text-center text-light font-weight-400 font-size-14">
              We all know energy costs can be unpredictable. Did you know you
              can take control and even earn rewards by participating in a<br />{" "}
              program that helps keep our community&apos;s electricity grid
              running smoothly?
            </Col>
          </Row>
          <Row className="mt-3 justify-content-center">
            <Col className="col-12 col-sm-6 col-lg-4 col-xl-2">
              <AppButton
                text="Join DRP"
                size="medium"
                onClick={() =>
                  deviceList.length > 0
                    ? setSelectedPage(2)
                    : setShowInfoModal(true)
                }
              />
            </Col>
          </Row>
        </div>
      ) : (
        selectedPage === 2 && (
          <>
            <Row>
              <Col>
                <JoinDrpHeader
                  onBack={() => {
                    if (selectedStep === 2) {
                      setSelectedStep(1);
                    } else if (isEdit) {
                      navigate(AppRoute.VIEW_DEMAND_RESPONSE_PROGRAM);
                    } else {
                      setSelectedPage(1);
                    }
                  }}
                  onConfirm={() => {
                    if (selectedStep === 1) {
                      setSelectedStep(2);
                      return;
                    }
                    if (isEdit) {
                      onSubmit();
                    } else {
                      setShowContinueModal(true);
                    }
                  }}
                  selectedStep={selectedStep}
                  isEdit={isEdit}
                  isDisabled={
                    selectedDeviceList.filter(
                      (sd) => sd.devicePriority as EDevicePriority
                    ).length <= 0
                  }
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <AppBannerWithIcon
                  bannerVariant="yellow"
                  icon="live_help"
                  iconVariant="#CBCD68"
                  content={
                    <>
                      <Row>
                        <Col className="text-dark font-weight-400 font-size-14px">
                          Don&apos;t Include:
                        </Col>
                      </Row>
                      <Row>
                        <Col className="text-light font-weight-400 font-size-14px">
                          We recommend that you don&apos;t include devices that
                          are not suitable for instant switch on/off in the DRP.
                        </Col>
                      </Row>
                    </>
                  }
                  alignItem="start"
                />
              </Col>
            </Row>
            {selectedStep === 1 ? (
              <div className="position-relative">
                {deviceList.length > 0 ? (
                  <Row className="mt-3 mx-0">
                    <Col>
                      {deviceList.map((d, index) => {
                        const foundSelectedDevice = selectedDeviceList.find(
                          (sd) => sd.smartDeviceId === d.smartDeviceId
                        );
                        return (
                          <Row key={index} className="mt-2">
                            <Col>
                              <DeviceItemCardWithAppSelect
                                device={{
                                  ...d,
                                  devicePriority: foundSelectedDevice
                                    ? foundSelectedDevice.devicePriority
                                    : undefined,
                                }}
                                setSelectedDeviceList={setSelectedDeviceList}
                                selectedStep={selectedStep}
                                currentHighPriorityDeviceOrder={
                                  currentHighPriorityDeviceOrder
                                }
                                currentLowPriorityDeviceOrder={
                                  currentLowPriorityDeviceOrder
                                }
                                setCurrentHighPriorityDeviceOrder={
                                  setCurrentHighPriorityDeviceOrder
                                }
                                setCurrentLowPriorityDeviceOrder={
                                  setCurrentLowPriorityDeviceOrder
                                }
                              />
                            </Col>
                          </Row>
                        );
                      })}
                    </Col>
                  </Row>
                ) : (
                  <div className="container-dash my-3 text-dark font-size-14 font-weight-400">
                    There are no devices.
                  </div>
                )}
                <SpinnerModal show={isFetchingListSmartControllers} />
              </div>
            ) : (
              <>
                <Row className="mt-4">
                  <Col className="col-auto text-dark font-weight-500">
                    High Priority Devices
                    <span className="text-light font-size-12 font-weight-400 ms-1">{`(Drag to Create Preferred Order)`}</span>
                  </Col>
                </Row>
                {selectedDeviceList.filter(
                  (sd) => sd.devicePriority === EDevicePriority.HIGH_PRIORITY
                ).length > 0 ? (
                  <Row className="mx-0">
                    <Col>
                      <DragDropContext onDragEnd={highPriorityOnDragEnd}>
                        <Droppable droppableId="high-priority-droppable">
                          {(provided: DroppableProvided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              {selectedDeviceList
                                .filter(
                                  (sd) =>
                                    sd.devicePriority ===
                                    EDevicePriority.HIGH_PRIORITY
                                )
                                .sort((a, b) => a.deviceOrder - b.deviceOrder)
                                .map((d, index) => {
                                  return (
                                    <Draggable
                                      key={d.smartDeviceId}
                                      draggableId={d.smartDeviceId}
                                      index={index}
                                    >
                                      {(
                                        providedDraggable: DraggableProvided
                                      ) => (
                                        <div>
                                          <div
                                            ref={providedDraggable.innerRef}
                                            {...providedDraggable.draggableProps}
                                            {...providedDraggable.dragHandleProps}
                                          >
                                            <Row className="mt-2">
                                              <Col>
                                                <DeviceItemCardWithAppSelect
                                                  device={d}
                                                  setSelectedDeviceList={
                                                    setSelectedDeviceList
                                                  }
                                                  selectedStep={selectedStep}
                                                />
                                              </Col>
                                            </Row>
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                })}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </Col>
                  </Row>
                ) : (
                  <div className="container-dash my-2 text-dark font-size-14 font-weight-400">
                    There are no added High Priority Devices.
                  </div>
                )}
                <Row className="mt-4">
                  <Col className="col-auto text-dark font-weight-500">
                    Low Priority Devices
                    <span className="text-light font-size-12 font-weight-400 ms-1">{`(Drag to Create Preferred Order)`}</span>
                  </Col>
                </Row>
                {selectedDeviceList.filter(
                  (sd) => sd.devicePriority === EDevicePriority.LOW_PRIORITY
                ).length > 0 ? (
                  <Row className="mx-0">
                    <Col>
                      <DragDropContext onDragEnd={lowPriorityOnDragEnd}>
                        <Droppable droppableId="low-priority-droppable">
                          {(provided: DroppableProvided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              {selectedDeviceList
                                .filter(
                                  (sd) =>
                                    sd.devicePriority ===
                                    EDevicePriority.LOW_PRIORITY
                                )
                                .sort((a, b) => a.deviceOrder - b.deviceOrder)
                                .map((d, index) => {
                                  return (
                                    <Draggable
                                      key={d.smartDeviceId}
                                      draggableId={d.smartDeviceId}
                                      index={index}
                                    >
                                      {(
                                        providedDraggable: DraggableProvided
                                      ) => (
                                        <div>
                                          <div
                                            ref={providedDraggable.innerRef}
                                            {...providedDraggable.draggableProps}
                                            {...providedDraggable.dragHandleProps}
                                          >
                                            <Row className="mt-2">
                                              <Col>
                                                <DeviceItemCardWithAppSelect
                                                  device={d}
                                                  setSelectedDeviceList={
                                                    setSelectedDeviceList
                                                  }
                                                  selectedStep={selectedStep}
                                                />
                                              </Col>
                                            </Row>
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                })}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </Col>
                  </Row>
                ) : (
                  <div className="container-dash my-2 text-dark font-size-14 font-weight-400">
                    There are no added Low Priority Devices.
                  </div>
                )}
              </>
            )}
          </>
        )
      )}
      <ContinueModal
        show={showContinueModal}
        onClose={() => setShowContinueModal(false)}
        onCancel={() => setShowContinueModal(false)}
        deviceCount={selectedDeviceList.length}
        onConfirm={onSubmit}
      />
      <InfoModal
        show={showInfoModal}
        message={
          <>
            <Row>
              <Col>
                There must be at least one active and specific Smart Plug in
                order to continue with the DRP.
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <ul>
                  <li>
                    If you haven&apos;t added any smart plugs yet, please add at
                    least one Specific type smart plug.
                  </li>
                  <li>
                    If your smart plugs are disabled, please link at least one
                    with a device.
                  </li>
                  <li>
                    If you have only General smart plugs, you need to have at
                    least one Specific type smart plug.
                  </li>
                </ul>
              </Col>
            </Row>
          </>
        }
        onConfirm={() => setShowInfoModal(false)}
        onClose={() => setShowInfoModal(false)}
        onCancel={() => setShowInfoModal(false)}
        hideCancel={true}
      />
      <SpinnerModal show={isFetchingListSmartControllers} />
    </div>
  );
};

export default JoinDemandResponseProgram;
