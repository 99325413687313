import { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useAddAcControllerMutation,
  useAddControllerMutation,
  useLazyViewManufacturedDeviceBySerialKeyQuery,
  useUpdateAcControllerMutation,
  useUpdateControllerMutation,
} from "../../../../../redux/api/controller/controllerAPI";
import {
  useLazyGetSpaceHierarchyQuery,
  useLazyGetSpacesQuery,
} from "../../../../../redux/api/space/spaceAPI";
import { useLazyCheckIfSmartPlugRegisteredToDRPQuery } from "../../../../../redux/api/usage-guide/usageGuideAPI";
import { selectSmartControllersFilter } from "../../../../../redux/features/smart-controllers-filter/smart-controllers-filter-slice";
import LinkDeviceCard from "../../../../../shared/components/link-device-card/link-device-card";
import serialKey from "../../../../../shared/oversight-core/assets/images/image 2.png";
import CheckIfSmartPlugRegisteredResponseDTO from "../../../../../shared/oversight-core/dtos/response-dtos/check-if-smart-plug-registered-response-dto";
import { EAcProtocolType } from "../../../../../shared/oversight-core/enums/ac-protocol-type";
import { EActiveStatus } from "../../../../../shared/oversight-core/enums/active-status";
import { EConnectionStatus } from "../../../../../shared/oversight-core/enums/connection-status";
import { EDeviceStatus } from "../../../../../shared/oversight-core/enums/device-status";
import { EDeviceTypes } from "../../../../../shared/oversight-core/enums/device-types";
import { OvstErrorCode } from "../../../../../shared/oversight-core/enums/ovst-error-codes";
import { ESmartControllerType } from "../../../../../shared/oversight-core/enums/smart-controller-type";
import { ESmartPlugType } from "../../../../../shared/oversight-core/enums/smart-plug-type";
import { AppRoute } from "../../../../../shared/oversight-core/interfaces/app-routes";
import { ISpaceView } from "../../../../../shared/oversight-core/interfaces/entities/space";
import { IRegisteringSmartDevice } from "../../../../../shared/oversight-core/interfaces/registering-smart-device";
import { ISelectedSpace } from "../../../../../shared/oversight-core/interfaces/selected-space";
import { ISpaceClusterSelectedPowerConsumerViews } from "../../../../../shared/oversight-core/interfaces/space-cluster-selected-power-consumer-views";
import { ISpaceClusterShallowHierarchyView } from "../../../../../shared/oversight-core/interfaces/space-hierarchy";
import AppSelect, {
  Option,
} from "../../../../../shared/oversight-core/ui-elements/app-select/app-select";
import AppButton from "../../../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import AppInput from "../../../../../shared/oversight-core/ui-elements/input/app-input";
import MaterialIcon from "../../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import SpaceSelectorDropdown from "../../../../../shared/oversight-core/ui-elements/space-selector-dropdown/space-selector-dropdown";
import SpinnerModal from "../../../../../shared/oversight-core/ui-elements/spinner/spinner";
import { noSpecialCharsNoWhitespaceStartEndAndAllowCharacters } from "../../../../../shared/oversight-core/utils/regex";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../../shared/oversight-core/utils/toast";
import GeneralControllerLinkConfirmModal from "../../../space-clusters/components/models/general-controller-link-confirm-modal/general-controller-link-confirm-modal";
import DeviceLinkModal from "../models/device-link-modal/device-link-modal";
import DeviceUnlinkConfirmationModal from "../models/device-unlink-confirmation-modal/device-unlink-confirmation-modal";
import SpecificControllerConvertToGeneralControllerConfirmModal from "../models/specific-controller-unlink-confirm-modal/specific-controller-unlink-confirm-modal";
import styles from "./add-update-controllers.module.scss";

interface IFormInput extends Omit<IRegisteringSmartDevice, "linkedDeviceId"> {
  deviceType: Option | [];
}

const defaultFormValues: IFormInput = {
  name: "",
  deviceType: [],
  serialKey: "",
  smartPlugType: "",
};

const defaultSelectedDevice: ISpaceClusterSelectedPowerConsumerViews = {
  spaceClusterId: "",
  spaceId: "",
  powerConsumer: {
    id: "",
    name: "",
    deviceType: EDeviceTypes.ALL_DEVICES,
    deviceCategory: "",
    powerUsageInWatt: 0,
    brand: "",
    modelNumber: "",
    serialKey: "",
    deviceConnectionState: EConnectionStatus.DISCONNECTED,
    devicePowerState: EDeviceStatus.OFF,
    protocol: EAcProtocolType.COOLIX,
  },
  path: {
    id: "",
    label: "",
    ancestorSpaces: [{ id: "", name: "", parentSpaceId: "" }],
  },
};

const AddUpdateController = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const smartPlugType: Option[] = [
    { value: ESmartPlugType.GENERAL, label: "General Controller" },
    {
      value: ESmartPlugType.SPECIFIC,
      label: "Specific (Must link with a device)",
    },
  ];

  const defaultSmartPlugTypeType = smartPlugType[0];
  const [selectedSmartPlugType, setSelectedSmartPlugType] = useState<Option>({
    ...defaultSmartPlugTypeType,
  });
  const [isLinkDevice, setIsLinkDevice] = useState(false);
  const [showDeviceLinkModal, setShowDeviceLinkModal] = useState(false);
  const [selectedDevice, setSelectedDevice] =
    useState<ISpaceClusterSelectedPowerConsumerViews>({
      ...defaultSelectedDevice,
    });
  const [spaceClusters, setSpaceClusters] = useState<ISpaceView[]>([]);
  const [isLinkedDeviceError, setIsLinkDeviceError] = useState(false);
  const [isSpaceError, setIsSpaceError] = useState(false);
  const [selectedSpace, setSelectedSpace] = useState<ISelectedSpace>({
    clusterId: "",
    id: "",
    name: "Select a Space",
  });
  const [serialKeyDeviceType, setSerialKeyDeviceType] =
    useState<ESmartControllerType>();
  const [isUsedSerialKey, setIsUsedSerialKey] = useState(false);
  const [isSerialKeyNotFound, setIsSerialKeyNotFound] = useState(false);
  const [excludedSemiAutoScheduleList, setExcludedSemiAutoScheduleList] =
    useState<string[]>([]);
  const [
    showGeneralControllerLinkConfirmModal,
    setShowGeneralControllerLinkConfirmModal,
  ] = useState(false);
  const [
    showSpecificControllerConvertToGeneralControllerConfirmModal,
    setShowSpecificControllerConvertToGeneralControllerConfirmModal,
  ] = useState(false);
  const [isPathRevers, setIsPathRevers] = useState(true);
  const [
    showDeviceUnlinkConfirmationModal,
    setShowDeviceUnlinkConfirmationModal,
  ] = useState(false);
  const [unlinkPowerConsumerName, setIsUnlinkPowerConsumerName] = useState("");
  const [isRemoveDeviceFromDRP, setIsRemoveDeviceFromDRP] = useState(false);
  const [isRegisteredDRP, setIsRegisteredDRP] = useState(false);
  const [updateDRPRegisterStatus, setUpdateDRPRegisterStatus] = useState(false);

  const smartControllersFiltersStore = useSelector(
    selectSmartControllersFilter
  );
  const [addController, { isLoading: isLoadingAddController }] =
    useAddControllerMutation();
  const [addAcController, { isLoading: isLoadingAddAcController }] =
    useAddAcControllerMutation();
  const [updateController, { isLoading: isLoadingUpdateController }] =
    useUpdateControllerMutation();
  const [updateAcController, { isLoading: isLoadingUpdateAcController }] =
    useUpdateAcControllerMutation();
  const [
    triggerViewManufacturedDeviceBySerialKey,
    { isFetching: isFetchingViewManufacturedDeviceBySerialKey },
  ] = useLazyViewManufacturedDeviceBySerialKeyQuery();
  const [triggerGetSpaces] = useLazyGetSpacesQuery();
  const [triggerGetHierarchy, { isFetching: isFetchingHierarchy }] =
    useLazyGetSpaceHierarchyQuery();
  const [checkIfSmartPlugRegisteredToDRP] =
    useLazyCheckIfSmartPlugRegisteredToDRPQuery();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: { ...defaultFormValues },
  });

  const currentSerialKey = watch("serialKey", "");
  const currentName = useRef("");
  currentName.current = watch("name", "");

  useEffect(() => {
    reset({ ...defaultFormValues });
    if (state && state.smartDevice) {
      triggerGetHierarchy({
        clusterId: state.smartDevice.clusterId,
        spaceId: state.smartDevice.spaceId,
      })
        .unwrap()
        .then((res: ISpaceClusterShallowHierarchyView) => {
          setSelectedSpace({
            clusterId: res.id,
            id: res.ancestorSpaces[res.ancestorSpaces.length - 1].id,
            name: res.ancestorSpaces[res.ancestorSpaces.length - 1].name,
          });
          setValue("name", state.smartDevice.name);
          setValue("serialKey", state.smartDevice.serialKey);
          setSelectedSmartPlugType(
            state.smartDevice.linkedPowerConsumer
              ? smartPlugType[1]
              : smartPlugType[0]
          );
          setIsLinkDevice(state.smartDevice.linkedPowerConsumer ? true : false);
          setSelectedDevice({
            spaceClusterId: state.smartDevice.clusterId,
            spaceId: state.smartDevice.spaceId,
            powerConsumer: {
              id: state.smartDevice.linkedPowerConsumer?.id,
              name: state.smartDevice.linkedPowerConsumer?.name,
              deviceType: state.smartDevice.linkedPowerConsumer?.deviceType,
              deviceCategory:
                state.smartDevice.linkedPowerConsumer?.deviceCategory,
              powerUsageInWatt:
                state.smartDevice.linkedPowerConsumer?.powerUsageInWatt,
              brand: "",
              modelNumber: "",
              serialKey: state.smartDevice.serialKey,
              deviceConnectionState: state.smartDevice.deviceConnectionState,
              devicePowerState: state.smartDevice.devicePowerState,
              protocol: state.smartDevice.linkedPowerConsumer?.protocol,
            },
            path: res,
          });
          setIsPathRevers(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [state?.smartDevice]);

  useEffect(() => {
    triggerGetSpaces()
      .unwrap()
      .then((response) => {
        let spaceClusterView: ISpaceView[] = [];
        for (const spaceCluster of response.spaceClusters) {
          spaceClusterView.push({
            ...spaceCluster.rootSpace,
            tariffCode: spaceCluster.serviceProviderAccount.tariffCode,
            accountNumber: spaceCluster.serviceProviderAccount.accountNumber,
            accountNumberLabel: spaceCluster.serviceProviderAccount.label,
            clusterId: spaceCluster.id,
          });
        }
        spaceClusterView = spaceClusterView.sort((a, b) =>
          a.name.trim().localeCompare(b.name.trim())
        );
        setSpaceClusters([...spaceClusterView]);
      })
      .catch(() => {
        setSpaceClusters([]);
      });
  }, []);

  useEffect(() => {
    if (
      selectedSmartPlugType.value === ESmartPlugType.GENERAL &&
      selectedSpace.clusterId &&
      selectedSpace.id
    ) {
      setIsSpaceError(false);
    }
  }, [selectedSpace.clusterId, selectedSpace.id, selectedSmartPlugType]);

  useEffect(() => {
    if (state?.smartDevice) {
      checkIfSmartPlugRegisteredToDRP({
        spaceClusterId: state.smartDevice.clusterId,
        spaceId: state.smartDevice.spaceId,
        smartPlugId: state.smartDevice.id,
      })
        .unwrap()
        .then((res: CheckIfSmartPlugRegisteredResponseDTO) => {
          setIsRegisteredDRP(res.registered);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [state?.smartDevice, updateDRPRegisterStatus]);

  const showControllerErrorMessage = (errorCode: OvstErrorCode) => {
    switch (errorCode) {
      case OvstErrorCode.OVST_CONS_0020:
        showErrorMessage("Smart device exist with given serial key.");
        break;
      case OvstErrorCode.OVST_CONS_0019:
        showErrorMessage("Smart device exist with given name.");
        break;
      case OvstErrorCode.OVST_CONS_0026:
        showErrorMessage("Serial key is invalid");
        break;
      case OvstErrorCode.OVST_0052:
        showErrorMessage("Smart device already registered in a space.");
        break;
      case OvstErrorCode.OVST_0053:
        showErrorMessage("Smart device already linked to a power consumer.");
        break;
      case OvstErrorCode.OVST_0054:
        showErrorMessage("Power consumer is already linked to a smart device.");
        break;
      case OvstErrorCode.OVST_CONN_0001:
        showErrorMessage("Device is not online.");
        break;
      case OvstErrorCode.OVST_CONN_0002:
        showErrorMessage("Invalid identity.");
        break;
      case OvstErrorCode.OVST_CONN_0003:
        showErrorMessage(
          "Failure in one of the services. Please try again later."
        );
        break;
      case OvstErrorCode.OVST_SEC_0004:
        showErrorMessage("Sorry error in the system please try again later!");
    }
  };

  const onSubmit = (data: IFormInput) => {
    if (state && state.smartDevice) {
      if (
        selectedSmartPlugType.value === ESmartPlugType.GENERAL &&
        !selectedSpace.clusterId &&
        !selectedSpace.id
      ) {
        setIsSpaceError(true);
      }
      if (
        selectedSmartPlugType.value === ESmartPlugType.GENERAL &&
        selectedSpace.clusterId &&
        selectedSpace.id
      ) {
        if (state.smartDevice.smartPlugType === ESmartPlugType.GENERAL) {
          updateController({
            spaceClusterId: state.smartDevice.clusterId,
            spaceId: selectedSpace
              ? selectedSpace.id
              : state.smartDevice.spaceId,
            smartPlugId: state.smartDevice.id,
            name: data.name,
            linkedPowerConsumerId: "",
            smartPlugType: selectedSmartPlugType.value as ESmartPlugType,
          })
            .unwrap()
            .then(() => {
              navigate(AppRoute.CONTROLLERS);
              showSuccessMessage("Smart Device Updated Successfully");
            })
            .catch((error) => {
              if (
                error.status === 412 &&
                error.ovstErrorCode === OvstErrorCode.OVST_CONN_0003
              ) {
                showErrorMessage(
                  "Failure in one of the services. Please try again later."
                );
                return;
              }
            });
        } else {
          setShowSpecificControllerConvertToGeneralControllerConfirmModal(true);
          return;
        }
      } else {
        if (!selectedDevice.powerConsumer.id) {
          setIsLinkDeviceError(true);
          return;
        }
        setIsLinkDeviceError(false);
        if (
          !state?.smartDevice.smartPlugType ||
          state?.smartDevice.smartPlugType === ESmartPlugType.SPECIFIC
        ) {
          if (
            isLinkDevice &&
            state.smartDevice.linkedPowerConsumer?.id &&
            state.smartDevice.linkedPowerConsumer?.id !==
              selectedDevice.powerConsumer.id
          ) {
            setShowDeviceUnlinkConfirmationModal(true);
            return;
          }
          if (
            state?.smartDevice.smartDeviceType ===
            ESmartControllerType.SMART_PLUG
          ) {
            updateController({
              spaceClusterId: state.smartDevice.clusterId,
              spaceId: state.smartDevice.spaceId,
              smartPlugId: state.smartDevice.id,
              name: data.name,
              linkedPowerConsumerId: selectedDevice.powerConsumer?.id,
              smartPlugType: selectedSmartPlugType.value as ESmartPlugType,
              isLinkOnly: true,
            })
              .unwrap()
              .then(() => {
                navigate(AppRoute.CONTROLLERS);
                showSuccessMessage("Smart Device Updated Successfully");
              })
              .catch((error) => {
                if (
                  error.status === 412 &&
                  error.ovstErrorCode === OvstErrorCode.OVST_CONN_0003
                ) {
                  showErrorMessage(
                    "Failure in one of the services. Please try again later."
                  );
                  return;
                }
              });
          } else {
            updateAcController({
              spaceClusterId: state.smartDevice.clusterId,
              spaceId: state.smartDevice.spaceId,
              acControllerId: state.smartDevice.id,
              name: data.name,
              linkedAcId: selectedDevice.powerConsumer.id,
            })
              .unwrap()
              .then(() => {
                navigate(AppRoute.CONTROLLERS);
                showSuccessMessage("AC Controller Updated Successfully");
              })
              .catch((error) => {
                if (
                  error.status === 412 &&
                  error.ovstErrorCode === OvstErrorCode.OVST_CONN_0003
                ) {
                  showErrorMessage(
                    "Failure in one of the services. Please try again later."
                  );
                  return;
                }
              });
          }
        } else {
          setShowGeneralControllerLinkConfirmModal(true);
        }
      }
    } else {
      if (
        selectedSmartPlugType.value === ESmartPlugType.GENERAL &&
        !selectedSpace.clusterId &&
        !selectedSpace.id
      ) {
        setIsSpaceError(true);
      }
      if (selectedSpace.clusterId && selectedSpace.id) {
        addController({
          spaceClusterId: selectedSpace.clusterId,
          spaceId: selectedSpace.id,
          registeringSmartPlugs: [
            {
              name: data.name,
              serialKey: data.serialKey,
              smartPlugType: selectedSmartPlugType.value as ESmartPlugType,
            },
          ],
        })
          .unwrap()
          .then(() => {
            navigate(AppRoute.CONTROLLERS);
            showSuccessMessage("Smart Device Added Successfully");
          })
          .catch((error) => {
            showControllerErrorMessage(error.ovstErrorCode as OvstErrorCode);
          });
      } else {
        if (!selectedDevice.powerConsumer.id) {
          setIsLinkDeviceError(true);
          return;
        }
        setIsLinkDeviceError(false);
        if (serialKeyDeviceType === ESmartControllerType.AC_CONTROLLER) {
          addAcController({
            spaceClusterId: selectedDevice.spaceClusterId,
            spaceId: selectedDevice.spaceId,
            registeringAcControllers: [
              {
                name: data.name,
                serialKey: data.serialKey,
                linkedAcId: selectedDevice.powerConsumer.id,
              },
            ],
          })
            .unwrap()
            .then(() => {
              navigate(AppRoute.CONTROLLERS);
              showSuccessMessage("AC Controller Added Successfully");
            })
            .catch((error) => {
              showControllerErrorMessage(error.ovstErrorCode as OvstErrorCode);
            });

          return;
        }
        addController({
          spaceClusterId: selectedDevice.spaceClusterId,
          spaceId: selectedDevice.spaceId,
          registeringSmartPlugs: [
            {
              name: data.name,
              serialKey: data.serialKey,
              linkedDeviceId: selectedDevice.powerConsumer.id,
              smartPlugType: selectedSmartPlugType.value as ESmartPlugType,
            },
          ],
        })
          .unwrap()
          .then(() => {
            navigate(AppRoute.CONTROLLERS);
            showSuccessMessage("Smart Device Added Successfully");
          })
          .catch((error) => {
            showControllerErrorMessage(error.ovstErrorCode as OvstErrorCode);
          });
      }
    }
  };

  const onConfirmSpecificControllerConvertToGeneralControllerUpdate = (
    data: IFormInput
  ) => {
    updateController({
      spaceClusterId: state.smartDevice.clusterId,
      spaceId: selectedSpace ? selectedSpace.id : state.smartDevice.spaceId,
      smartPlugId: state.smartDevice.id,
      name: data.name,
      linkedPowerConsumerId: "",
      smartPlugType: selectedSmartPlugType.value as ESmartPlugType,
      keepInProgramme: isRemoveDeviceFromDRP,
    })
      .unwrap()
      .then(() => {
        navigate(AppRoute.CONTROLLERS);
        showSuccessMessage("Smart Device Updated Successfully");
        setUpdateDRPRegisterStatus((ps) => !ps);
      })
      .catch((error) => {
        if (
          error.status === 412 &&
          error.ovstErrorCode === OvstErrorCode.OVST_CONN_0003
        ) {
          showErrorMessage(
            "Failure in one of the services. Please try again later."
          );
          return;
        }
      });
  };

  const onConfirmGeneralControllerConvertToSpecificControllerUpdate = (
    data: IFormInput
  ) => {
    updateController({
      spaceClusterId: state.smartDevice.clusterId,
      spaceId: state.smartDevice.spaceId,
      smartPlugId: state.smartDevice.id,
      name: data.name,
      linkedPowerConsumerId: selectedDevice.powerConsumer.id,
      smartPlugType: selectedSmartPlugType.value as ESmartPlugType,
    })
      .unwrap()
      .then(() => {
        navigate(AppRoute.CONTROLLERS);
        showSuccessMessage("Smart Device Updated Successfully");
      })
      .catch((error) => {
        if (
          error.status === 412 &&
          error.ovstErrorCode === OvstErrorCode.OVST_CONN_0003
        ) {
          showErrorMessage(
            "Failure in one of the services. Please try again later."
          );
          return;
        }
      });
  };

  const onConfirmDeviceUnlinkAndUpdateController = (data: IFormInput) => {
    if (serialKeyDeviceType === ESmartControllerType.AC_CONTROLLER) {
      updateAcController({
        spaceClusterId: state.smartDevice.clusterId,
        spaceId: state.smartDevice.spaceId,
        acControllerId: state.smartDevice.id,
        name: data.name,
        linkedAcId: selectedDevice.powerConsumer.id,
        excludedSemiAutomatedSchedules: excludedSemiAutoScheduleList,
      })
        .unwrap()
        .then(() => {
          navigate(AppRoute.CONTROLLERS);
          showSuccessMessage("AC Controller Updated Successfully");
        })
        .catch((error) => {
          if (
            error.status === 412 &&
            error.ovstErrorCode === OvstErrorCode.OVST_CONN_0003
          ) {
            showErrorMessage(
              "Failure in one of the services. Please try again later."
            );
            return;
          }
        });
    } else {
      updateController({
        spaceClusterId: state.smartDevice.clusterId,
        spaceId: state.smartDevice.spaceId,
        smartPlugId: state.smartDevice.id,
        name: data.name,
        linkedPowerConsumerId: selectedDevice.powerConsumer.id,
        excludedSemiAutomatedSchedules: excludedSemiAutoScheduleList,
        smartPlugType: selectedSmartPlugType.value as ESmartPlugType,
        keepInProgramme: isRemoveDeviceFromDRP,
      })
        .unwrap()
        .then(() => {
          navigate(AppRoute.CONTROLLERS);
          showSuccessMessage("Smart Device Updated Successfully");
        })
        .catch((error) => {
          if (
            error.status === 412 &&
            error.ovstErrorCode === OvstErrorCode.OVST_CONN_0003
          ) {
            showErrorMessage(
              "Failure in one of the services. Please try again later."
            );
            return;
          }
        });
    }
  };

  useEffect(() => {
    setIsSerialKeyNotFound(false);
    if (currentSerialKey.length > 7) {
      triggerViewManufacturedDeviceBySerialKey({
        serialKey: currentSerialKey,
      })
        .unwrap()
        .then((res) => {
          setSerialKeyDeviceType(res.smartDeviceType);
          setSelectedSmartPlugType(
            state?.smartDevice.smartPlugType === ESmartPlugType.SPECIFIC ||
              res.smartDeviceType === ESmartControllerType.AC_CONTROLLER
              ? smartPlugType[1]
              : smartPlugType[0]
          );
          if (!state || !state.smartDevice) {
            setIsUsedSerialKey(!res.isAvailable);
          }
        })
        .catch((error) => {
          setIsUsedSerialKey(false);
          setSerialKeyDeviceType(undefined);
          if (
            error.status === 412 &&
            error.ovstErrorCode === OvstErrorCode.OVST_CONN_0003
          ) {
            showErrorMessage(
              "Failure in one of the services. Please try again later."
            );
            return;
          }

          if (
            error.status === 404 &&
            error.ovstErrorCode === OvstErrorCode.OVST_CONS_0026
          ) {
            setIsSerialKeyNotFound(true);
            return;
          }
        });
    }
  }, [currentSerialKey]);

  return (
    <>
      <div className="container-white position-relative">
        <Row className="pb-xl-5">
          <Col className="col-lg-6 col-12">
            <AppInput
              className="font-size-14"
              label="Controller Name"
              placeholder="Controller Name"
              name="name"
              register={register("name", {
                required: "Controller name is required",
                maxLength: {
                  value: 25,
                  message:
                    "You have exceeded the maximum number of 25 characters in this field",
                },
                pattern: {
                  value: noSpecialCharsNoWhitespaceStartEndAndAllowCharacters,
                  message:
                    "Entered value can't start/end or contain only white spaces and can't contain any special characters.",
                },
              })}
              errors={errors}
            />
            <AppInput
              className="mt-4 font-size-14"
              label="Controller Serial Key"
              placeholder="Controller Serial Key"
              name="serialKey"
              register={register("serialKey", {
                required: "Serial Key is required",
                maxLength: {
                  value: 25,
                  message:
                    "You have exceeded the maximum number of 25 characters in this field",
                },
              })}
              errors={errors}
              disabled={state?.smartDevice}
            />
            {isUsedSerialKey &&
              currentSerialKey.length > 7 &&
              !isFetchingViewManufacturedDeviceBySerialKey && (
                <p className="error mt-2">
                  {`${
                    serialKeyDeviceType === ESmartControllerType.SMART_PLUG
                      ? "Smart device"
                      : "AC controller"
                  } already registered in a space`}
                </p>
              )}
            {isSerialKeyNotFound && currentSerialKey.length > 0 && (
              <p className="error mt-2">
                Manufactured smart device is not found with the given serial key
              </p>
            )}
            {!isFetchingViewManufacturedDeviceBySerialKey ? (
              <>
                {currentSerialKey.length > 7 &&
                  serialKeyDeviceType === ESmartControllerType.SMART_PLUG &&
                  !isUsedSerialKey && (
                    <AppSelect
                      className="mt-4 font-size-14"
                      labelFontSize="14"
                      selectedValue={{ ...selectedSmartPlugType }}
                      label="Controller Type"
                      options={[...smartPlugType]}
                      onChangeOption={(selectedControllerOption) => {
                        if (
                          state &&
                          state.smartDevice &&
                          !state.smartDevice.linkedPowerConsumer
                        ) {
                          setIsLinkDevice(false);
                        }

                        setSelectedSpace({
                          clusterId: "",
                          id: "",
                          name: "Select a Space",
                        });
                        setSelectedSmartPlugType(selectedControllerOption);
                      }}
                      name="deviceType"
                      placeholder="Specific (Must link with a device)"
                      errors={errors}
                      isRequired
                      id="controller-type"
                    />
                  )}
                {currentSerialKey.length > 7 &&
                  !isUsedSerialKey &&
                  (selectedSmartPlugType.value === ESmartPlugType.GENERAL &&
                  serialKeyDeviceType === ESmartControllerType.SMART_PLUG ? (
                    <div className="mt-4">
                      <SpaceSelectorDropdown
                        dropdownOptions={[
                          ...spaceClusters
                            .filter(
                              (space) =>
                                space.clusterId ===
                                smartControllersFiltersStore.selectedFilterSpace
                                  .clusterId
                            )
                            .map((space) => {
                              return {
                                clusterId: space.clusterId,
                                id: space.id,
                                name: space.name,
                                childSpaces: space.childSpaces,
                              };
                            }),
                        ]}
                        disabled={false}
                        selectedSubSpace={selectedSpace.name}
                        updateSelectedSubSpaceId={(
                          selectedSubSpaceId: string,
                          selectedSubSpaceName: string,
                          clusterId?: string
                        ) => {
                          setSelectedSpace({
                            id: selectedSubSpaceId,
                            name: selectedSubSpaceName,
                            clusterId: clusterId || "",
                          });
                        }}
                        label="Space"
                        className="font-weight-400 font-size-14 text-light"
                        maxHeight="165px"
                        isRequired
                      />
                      {isSpaceError && (
                        <Row className="">
                          <Col className="error">Please select a space</Col>
                        </Row>
                      )}
                    </div>
                  ) : (
                    serialKeyDeviceType !== undefined &&
                    !isUsedSerialKey && (
                      <>
                        <div className="ms-1 mt-4">
                          <Row>
                            <Col className="font-weight-400 font-size-14">
                              Device<label className="asterisk">*</label>
                            </Col>
                          </Row>
                          <Row>
                            {isLinkDevice ? (
                              <Col className="px-4">
                                <LinkDeviceCard
                                  device={selectedDevice}
                                  icon="link_off"
                                  onUnlink={() => {
                                    setIsLinkDevice(false);
                                    setSelectedDevice({
                                      ...defaultSelectedDevice,
                                    });
                                    setIsUnlinkPowerConsumerName(
                                      state?.smartDevice.linkedPowerConsumer
                                        ?.name
                                    );
                                  }}
                                  controllerName={currentName.current}
                                  setExcludedSemiAutoScheduleList={
                                    setExcludedSemiAutoScheduleList
                                  }
                                  setIsRemoveDeviceFromDRP={
                                    setIsRemoveDeviceFromDRP
                                  }
                                  isPathRevers={isPathRevers}
                                  isRegisteredDRP={isRegisteredDRP}
                                  smartPlugId={state?.smartDevice.id}
                                />
                              </Col>
                            ) : (
                              <Col className="px-4">
                                <Row
                                  className={`align-items-center py-2 cursor-pointer ${styles.unlinkCard}`}
                                >
                                  <Col
                                    xs={"auto"}
                                    onClick={() => {
                                      setShowDeviceLinkModal(true);
                                    }}
                                  >
                                    <MaterialIcon icon="link" color="#2F2A89" />
                                  </Col>
                                  <Col
                                    className="text-light font-size-14 font-weight-500 ps-0"
                                    onClick={() => {
                                      setShowDeviceLinkModal(true);
                                    }}
                                  >
                                    Link Device
                                  </Col>
                                </Row>
                                {isLinkedDeviceError && (
                                  <Row>
                                    <Col className="error">
                                      {serialKeyDeviceType ===
                                      ESmartControllerType.AC_CONTROLLER
                                        ? ` AC controllers must be
                                      linked with a device`
                                        : ` Specific type smart controllers must be
                                      linked with a device`}
                                    </Col>
                                  </Row>
                                )}
                              </Col>
                            )}
                          </Row>
                          <Row className="mt-2">
                            {isLinkDevice ? (
                              <Col className="px-4 font-size-14 text-muted font-weight-400">
                                * Path of the device will be used as the path of
                                the Controller
                              </Col>
                            ) : (
                              ""
                            )}
                          </Row>
                        </div>
                      </>
                    )
                  ))}
              </>
            ) : (
              <Row className="mt-4 align-items-center">
                <Col className="col-auto">
                  <SpinnerModal
                    show={isFetchingViewManufacturedDeviceBySerialKey}
                    withOverlay={false}
                  />
                </Col>
                <Col className="text-light font-size-14 font-weight-400 px-0">
                  Verifying
                </Col>
              </Row>
            )}
          </Col>
          <Col className="col-lg-6 col-12 px-4 mt-4">
            <Row className={`py-3 ${styles.socketTypes}`}>
              <Col className="col-auto">
                <MaterialIcon icon="live_help" color=" #A7AA18" />
              </Col>
              <Col className="ps-0">
                <Row>
                  <Col className="font-size-14 text-muted font-weight-400">
                    Controller serial key can be found at the back of the
                    device.
                    <br />
                    ex:
                  </Col>
                </Row>
                <Row>
                  <Col className="ps-1">
                    <img src={serialKey} alt="Serial Key" />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col className="font-size-14">
                    <div className="font-weight-500">Controller Types:</div>
                    <div className="font-weight-400 text-light mt-1">
                      Specific-Controller must be connected with a device to be
                      functioned.
                      <br />
                      General - Controller can work alone without connecting to
                      device.
                    </div>
                    <div className="font-weight-400 text-light mt-3">
                      AC Controller must be connected with an Air Conditioner.
                      <br />
                      This will allow the AC Controller to control the linked
                      Air Conditioner if <br />
                      the Air Conditioner is of a supported brand.
                    </div>
                    <div className="font-weight-400 text-light mt-3">
                      Air Conditioners with &quot;Other&quot; type communication
                      protocol will not be listed here
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-between mt-5">
          <Col xs="auto">
            <AppButton
              text="Cancel"
              variant="transparent"
              size="medium"
              className="px-2"
              onClick={() => {
                reset({ ...defaultFormValues });
                navigate(-1);
              }}
            />
          </Col>
          <Col xs="auto">
            <AppButton
              text="Confirm"
              variant={"blue"}
              size="medium"
              onClick={handleSubmit(onSubmit)}
              isLoading={
                isLoadingAddController ||
                (isLoadingUpdateController &&
                  state?.smartDevice.activeStatus === EActiveStatus.DISABLED &&
                  state?.smartDevice.linkedPowerConsumer === null) ||
                isLoadingAddAcController ||
                isLoadingUpdateAcController
              }
              id="add-controller-confirm"
            />
          </Col>
        </Row>
        <SpinnerModal show={isFetchingHierarchy} />
      </div>
      <DeviceLinkModal
        show={showDeviceLinkModal}
        onClose={() => {
          setShowDeviceLinkModal(false);
        }}
        onCancel={() => {
          setShowDeviceLinkModal(false);
        }}
        serialNumber={currentSerialKey}
        controllerName={currentName.current}
        sendSelectedDevice={(device) => {
          setSelectedDevice(device);
          setIsLinkDevice(true);
          setIsPathRevers(true);
        }}
        onConfirm={() => {
          setShowDeviceLinkModal(false);
        }}
        deviceConnectionState={state && state.smartDevice.deviceConnectionState}
        serialKeyDeviceType={
          serialKeyDeviceType || ESmartControllerType.SMART_PLUG
        }
      />
      <SpecificControllerConvertToGeneralControllerConfirmModal
        show={showSpecificControllerConvertToGeneralControllerConfirmModal}
        onClose={() =>
          setShowSpecificControllerConvertToGeneralControllerConfirmModal(false)
        }
        onCancel={() =>
          setShowSpecificControllerConvertToGeneralControllerConfirmModal(false)
        }
        onConfirm={handleSubmit(
          onConfirmSpecificControllerConvertToGeneralControllerUpdate
        )}
        deviceName={selectedDevice.powerConsumer.name}
        controllerName={state && state.smartDevice.name}
        isLoading={isLoadingUpdateController}
      />
      <GeneralControllerLinkConfirmModal
        show={showGeneralControllerLinkConfirmModal}
        onClose={() => setShowGeneralControllerLinkConfirmModal(false)}
        onCancel={() => setShowGeneralControllerLinkConfirmModal(false)}
        onConfirm={handleSubmit(
          onConfirmGeneralControllerConvertToSpecificControllerUpdate
        )}
        isLoading={isLoadingUpdateController}
      />
      <DeviceUnlinkConfirmationModal
        show={showDeviceUnlinkConfirmationModal}
        onCancel={() => setShowDeviceUnlinkConfirmationModal(false)}
        onClose={() => setShowDeviceUnlinkConfirmationModal(false)}
        onConfirm={handleSubmit(onConfirmDeviceUnlinkAndUpdateController)}
        controllerName={state && state.smartDevice.name}
        unlinkPowerConsumerName={unlinkPowerConsumerName}
        linkPowerConsumerName={selectedDevice.powerConsumer.name}
        isLoading={isLoadingUpdateController}
      />
    </>
  );
};

export default AddUpdateController;
