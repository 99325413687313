import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLazyViewConsumerDemandControlActionQuery } from "../../../../../../redux/api/usage-guide/usageGuideAPI";
import DeviceItemCard from "../../../../../../shared/components/device-item-card/device-item-card";
import ViewConsumerDemandControlActionResponseDTO from "../../../../../../shared/oversight-core/dtos/response-dtos/view-consumer-demand-control-action-response-dto";
import { EDemandControlRequestType } from "../../../../../../shared/oversight-core/enums/demand-control-request-type";
import { IDemandControlRequestView } from "../../../../../../shared/oversight-core/interfaces/demand-control-request-view";
import { IPowerConsumerWithPathView } from "../../../../../../shared/oversight-core/interfaces/power-consumer-with-path-view";
import { IShallowSemiAutomatedScheduleView } from "../../../../../../shared/oversight-core/interfaces/shallow-semi-automated-schedule-view";
import MaterialIcon from "../../../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../../../shared/oversight-core/ui-elements/modal-container/modal-container";
import SpinnerModal from "../../../../../../shared/oversight-core/ui-elements/spinner/spinner";
import { formatTime } from "../../../../../../shared/oversight-core/utils/time-utils";
import styles from "./usage-guide-info-modal.module.scss";

interface IProps extends ModelContainerProps {
  show: boolean;
  demandControlRequestId: string;
}

const UsageGuideInfoModal = (props: IProps) => {
  const { show, demandControlRequestId, ...rest } = props;

  const [demandControlRequestView, setDemandControlRequestView] =
    useState<IDemandControlRequestView>();
  const [powerConsumerViews, setPowerConsumers] =
    useState<IPowerConsumerWithPathView[]>();
  const [semiAutomatedScheduleViews, setSemiAutomatedScheduleViews] =
    useState<IShallowSemiAutomatedScheduleView[]>();

  const [
    triggerConsumerDemandControlAction,
    { isFetching: isFetchingConsumerDemandControlAction },
  ] = useLazyViewConsumerDemandControlActionQuery();

  useEffect(() => {
    if (show) {
      triggerConsumerDemandControlAction({ demandControlRequestId })
        .unwrap()
        .then((res: ViewConsumerDemandControlActionResponseDTO) => {
          setDemandControlRequestView(res.demandControlRequestView);
          setPowerConsumers(res.powerConsumerViews);
          setSemiAutomatedScheduleViews(res.semiAutomatedScheduleViews);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [show, demandControlRequestId]);

  return (
    <ModalContainer
      title={
        <Row className="align-items-center">
          <Col className="font-size-20 col-auto pe-0">
            {demandControlRequestView?.demandControlRequestType ===
            EDemandControlRequestType.DEMAND_CONTROL_LIMIT
              ? `Usage is Limited`
              : `Reduction of Usage is Recommended`}
          </Col>
          <Col>
            <MaterialIcon
              icon={
                demandControlRequestView?.demandControlRequestType ===
                EDemandControlRequestType.DEMAND_CONTROL_LIMIT
                  ? "error"
                  : "release_alert"
              }
              color={
                demandControlRequestView?.demandControlRequestType ===
                EDemandControlRequestType.DEMAND_CONTROL_LIMIT
                  ? "#E8C304"
                  : "#EC7575"
              }
              size={22}
            />
          </Col>
        </Row>
      }
      {...rest}
      show={show}
      size="modal-lg"
      isButtonsVisible={false}
    >
      <>
        <div className="position-relative">
          <Row>
            <Col className="text-light font-size-14 font-weight-400">
              {` Usage of the following devices that are enrolled in the DRP will be
            restricted during ${formatTime(
              new Date(
                demandControlRequestView?.demandControlPeriod?.fromDate || ""
              )
            )} to ${formatTime(
                new Date(
                  demandControlRequestView?.demandControlPeriod?.toDate || ""
                )
              )} time period on  ${moment(
                demandControlRequestView?.demandControlDate
              ).format("DD/MM/YYYY")}`}
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="text-dark font-size-12 font-weight-400">
              Affected Devices:
            </Col>
          </Row>
          <Row className={`${styles.overflowDeviceList} mx-0`}>
            {powerConsumerViews && powerConsumerViews?.length > 0 ? (
              powerConsumerViews?.map((pc) => {
                return (
                  <Col key={pc.id} className="col-12 col-sm-6 py-1 px-3">
                    <DeviceItemCard device={pc} />
                  </Col>
                );
              })
            ) : (
              <div className="container-dash my-2 text-light font-size-14 font-weight-500">
                There are no affected devices
              </div>
            )}
          </Row>
          <Row className="mt-4">
            <Col className="font-weight-400 font-size-12 text-start">
              Affected Schedules
            </Col>
          </Row>
          <Row className="mx-0 mt-1 gap-2">
            {semiAutomatedScheduleViews &&
            semiAutomatedScheduleViews.length > 0 ? (
              semiAutomatedScheduleViews?.map((schedule, index) => (
                <Col key={index} className={`col-3 px-2 py-1 ${styles.label}`}>
                  <div className="font-weight-400 text-light font-size-10">
                    {schedule.title}
                  </div>
                  {schedule.scheduledPeriods.map((scheduleTime, index) => {
                    return (
                      <div
                        key={index}
                        className={`font-weight-500 ${styles.time}`}
                      >
                        {formatTime(new Date(scheduleTime.fromDate))} -{" "}
                        {formatTime(new Date(scheduleTime.toDate))}
                      </div>
                    );
                  })}
                </Col>
              ))
            ) : (
              <Col className="px-0 pe-2">
                <div className="container-dash my-2 text-light font-size-14 font-weight-500">
                  There are no affected schedules
                </div>
              </Col>
            )}
          </Row>
          <SpinnerModal show={isFetchingConsumerDemandControlAction} />
        </div>
      </>
    </ModalContainer>
  );
};

export default UsageGuideInfoModal;
