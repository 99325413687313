import { Col, Row } from "react-bootstrap";
import AppStore from "./assets/app-store.png";
import GooglePlay from "./assets/google-play.png";
import styles from "./registration-through-app.module.scss";

const RegistrationThroughApp = () => {
  return (
    <>
      <Row className="justify-content-center">
        <Col className={`${styles.registrationThrough}`}>
          Don’t have an account? Register via my LECO App
        </Col>
      </Row>
      <Row className="align-items-center g-2 mt-2 justify-content-center">
        <Col className="text-end">
          <a
            href="https://play.google.com/store/apps/details?id=com.leco.myleco"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={`${styles.appImage} img-fluid`}
              src={GooglePlay}
              alt="google play icon"
            />
          </a>
        </Col>
        <Col>
          <a
            href="https://apps.apple.com/lk/app/my-leco-app/id6452432831"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={`${styles.appImage} img-fluid`}
              src={AppStore}
              alt="app store icon"
            />
          </a>
        </Col>
      </Row>
    </>
  );
};

export default RegistrationThroughApp;
