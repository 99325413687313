import { Col, Row } from "react-bootstrap";
import MaterialIcon from "../material-icon/material-icon";
import styles from "./arrow-button.module.scss";

interface IProps {
  onBack: () => void;
}

const ArrowButton = (props: IProps) => {
  return (
    <Row>
      <Col className={`${styles.arrowBtn} col-auto bg-primary p-1`}>
        <MaterialIcon
          icon="arrow_left"
          className="text-white"
          style={{ cursor: "pointer" }}
          onClick={() => props.onBack()}
        />
      </Col>
    </Row>
  );
};

export default ArrowButton;
